import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { fetchData } from '../lib/helpers';
import { AccountMetaData, setCookie } from '../lib/account';

export default class RegisterView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataForm: {}
        };
    }
    onFinish = async (e) => {
        try {
            this.setState({ loading: true });
            e.preventDefault();
            let values = this.state.dataForm;
            console.log('Received values of form: ', values);
            let resutl = await fetchData({
                url: 'api/v1/register',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });
            this.setState({ loading: false }, () => {
                this.props.notification({
                    message: 'Đăng ký thành công',
                    description: 'Chúc mừng bạn đã đăng ký tài khoản thành công, giờ bạn có thể đăng nhập!',
                    type: 'success'
                })
                this.props.history.push('/');
            });

        } catch (err) {
            console.log(err);
            this.setState({ loading: false }, () => {
                message.warning({
                    content: err.message ? err.message : 'Đã có lỗi xảy ra!',
                    key: 'error'
                })
            });
        }
    };
    handleForm = (e) => {
        let dataForm = this.state.dataForm;
        dataForm[e.target.name] = e.target.value;
        this.setState({
            dataForm
        })
    }
    render() {
        return (
            <div id="page-container" className="main-content-boxed">
                <main id="main-container">

                    <div className="bg-body-dark bg-pattern" >
                        <div className="row mx-0 justify-content-center">
                            <div className="hero-static col-lg-6 col-xl-4">
                                <div className="content content-full overflow-hidden">
                                    <div className="py-30 text-center">
                                        <a className="link-effect font-w700" href="index.html">
                                            <i className="si si-fire"></i>
                                            <span className="font-size-xl text-primary-dark">code</span><span className="font-size-xl">base</span>
                                        </a>
                                        <h1 className="h4 font-w700 mt-30 mb-10">Đăng ký tài khoản mới</h1>
                                    </div>
                                    <form className="js-validation-signup" onSubmit={this.onFinish}>
                                        <div className="block block-themed block-rounded block-shadow">
                                            <div className="block-header bg-gd-emerald">
                                                <h3 className="block-title">Form đăng ký</h3>
                                                <div className="block-options">
                                                    <button type="button" className="btn-block-option">
                                                        <i className="si si-wrench"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="block-content">

                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label>Họ tên</label>
                                                        <input type="text" className="form-control" name="full_name" placeholder="eg: John Smith" 
                                                            value={this.state.dataForm ? this.state.dataForm.full_name : ''} 
                                                            onChange={this.handleForm}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label>Tên đăng nhập</label>
                                                        <input type="text" className="form-control" name="user_name" placeholder="eg: john_smith" 
                                                            value={this.state.dataForm ? this.state.dataForm.user_name : ''} 
                                                            onChange={this.handleForm}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label>Mật khẩu</label>
                                                        <input type="password" className="form-control" name="password" placeholder="********" 
                                                            value={this.state.dataForm ? this.state.dataForm.password : ''} 
                                                            onChange={this.handleForm}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="form-group row">
                                                    <div className="col-12">
                                                        <label>Nhập lại mật khẩu</label>
                                                        <input type="password" className="form-control" name="password-confirm" placeholder="********" />
                                                    </div>
                                                </div> */}
                                                <div className="form-group row mb-0">
                                                    <div className="col-sm-6 push">
                                                        {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="signup-terms" name="signup-terms" />
                                                            <label className="custom-control-label" htmlFor="signup-terms">Tôi đồng ý tất cả điều khoản</label>
                                                        </div> */}
                                                    </div>
                                                    <div className="col-sm-6 text-sm-right push">
                                                        <Button htmlType="submit" className="btn btn-alt-primary" loading={this.state.loading}>
                                                            <i className="si si-login mr-10"></i> Đăng ký
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="block-content bg-body-light">
                                                <div className="form-group text-center">
                                                    {/* <a className="link-effect text-muted mr-10 mb-5 d-inline-block" href="#" data-toggle="modal" data-target="#modal-terms">
                                                        <i className="fa fa-book text-muted mr-5"></i> Điều khoản
                                                     </a> */}
                                                    <Link className="link-effect text-muted mr-10 mb-5 d-inline-block" to="/">
                                                        <i className="fa fa-user text-muted mr-5"></i> Đăng nhập
                                                     </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
        )
    }
}
