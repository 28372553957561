import {AccountMetaData} from  './account';

export function fetchData(data = {}){
    let origin = process.env.REACT_APP_API || window.location.origin;

    let url = `${origin}/${data['url']}`;
    let options = {
        method: data.method ? data.method : 'GET'
    };
    if(data.body) options['body'] = data.body;
    options['headers'] = data['headers'] ? data['headers'] : {};

    let token = AccountMetaData.getToken();
    if(token) options['headers']['Authorization'] = `JWT ${token}`;
    
    if(data['full_path']){
        url = data['full_path'];
    }
    if(options.method.toLocaleLowerCase() != "get" && !options['headers']['Content-Type'] && !data.notAddContentType){
        options['headers']['Content-Type'] = "application/json";
    }
    if(data.params){
        let location = new URL(url);
        Object.keys(data.params).map((key) => {
            if(data.params[key] != undefined ) location.searchParams.set(key, data.params[key]);
        })
        url = location.href;
    }
    return new Promise(async (resolve, reject) => {
        try{
            let response = await fetch(url, options);
            response = await response.text();
            try{
                response = JSON.parse(response);
                if(response.code && response.code != 200 || response.status_code && response.status_code != 200) return reject(response);
                return resolve(response);
            } catch(err){
                console.log(response);
                console.error("ERROR#1", err);
                return resolve(response);
            }
        } catch(err){
            console.error("ERROR#2", err);
            return reject({
                code: 500,
                err: err || "Đã có lỗi xảy ra"
            });
        }
    })
}

export function formatNumber(value) {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  }

  export function getImageApi(str, t){
    let origin = process.env.REACT_APP_API || window.location.origin;
    let url = new URL(`${origin}/${str}`);
    if(t){
        url.searchParams.set("t", Date.now());
    }
    return url.href;
  }