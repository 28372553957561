import { ArrowLeftOutlined, DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Form, Input, Modal, notification, Select, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";
import { Config } from "../../../utils/config";
import { connect } from "react-redux";

import WidgetFormCreate from "./Widget/FormCreate";

const TaskAction = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [task, settask] = useState({
        name: ""
    });
    const [pagination, setpagination] = useState({
        limit: 20,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleForm, setVisibleForm] = useState({
        visible: false,
        item: null
    });
    const [initialValues, setinitialValues] = useState({
        device_id: new URLSearchParams(props.location.search).get("device_id")
    })

    const refFormFilter = useRef();
    const timeoutSearch = useRef();
    const task_id = props.match.params.id;

    const { Platform } = props.Access;

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination
            }
            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }
            let response = await fetchData({
                url: API_URL.taskAction.index + '/' + task_id,
                params: params
            });
            setData(response.data);
            settask(response.task);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: API_URL.aciton.delete,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    const ListActionType = useMemo(() => {
        if (task) {
            return Platform.actions.filter((item) => item.platform == task.action_type)
        } else {
            return []
        }
    }, [task, Platform])

    const convertBadge = useMemo(() => {
        switch (task.action_type) {
            case "facebook":
                return (
                    <>
                        <i class="fab fa-facebook-f"></i>
                        {` ${task.action_type}`}
                    </>
                );
            case "instagram":
                return (
                    <>
                        <i class="fab fa-instagram"></i>
                        {` ${task.action_type}`}
                    </>
                );
            case "kakaotalk":
                return "Kakao Talk";

            default:
                return "platform";
        }
    }, [task])

    useEffect(() => {
        getListData();
    }, [pagination]);

    const listActionName = useMemo(() => {
        let data = Platform.actions.filter((item) => item.platform == (task && task.action_type));
        return data;
    }, [task, Platform])

    useEffect(() => {
        if (task && listActionName.length) {
            let is_open = new URLSearchParams(props.location.search).get("open_setting")
            if (is_open == 1 && !visibleForm.is_open) {
                setVisibleForm({
                    visible: true,
                    item: task,
                    viewDetail: true,
                    list_uids: task.list_uids,
                    list_groups: task.list_groups,
                    is_open: true
                })
            }
        }
    }, [task, listActionName ])

    return (
        <React.Fragment>
            <WidgetFormCreate
                {...visibleForm}
                listActionName={listActionName}
                hide={() => setVisibleForm({ ...visibleForm, visible: false })}
                onFinish={() => {
                    setVisibleForm({ ...visibleForm, visible: false });
                }}
                platform={task && task.action_type}
            />

            <div className="block">
                <Badge.Ribbon text={convertBadge} />
                <div className="block-content block-content-m">
                    <Button className="d-flex align-items-center mb-10" size="small"
                        onClick={() => props.history.goBack()}
                    ><ArrowLeftOutlined /> Quay lại</Button>
                    <div className="d-flex justify-content-between">
                        <h2 className="block-title mb-20 font-weight-bold">Chi tiết hành động: {task.name}</h2>
                    </div>
                    <div className="">
                        <Form
                            ref={refFormFilter}
                            className="row gx-10"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = (e.keyword || e.device_id || e.log) ? 300 : 0;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                }, timeout);
                            }}
                            onReset={() => {
                                refFormFilter.current.setFieldsValue({
                                    device_id: undefined
                                })
                                getListData();
                            }}
                            initialValues={initialValues}
                        >
                            <Form.Item name="uid" className="col-md-2">
                                <Input placeholder="UID | tên đăng nhập" />
                            </Form.Item>
                            <Form.Item name="device_id" className="col-md-2">
                                <Input placeholder="Thiết bị" />
                            </Form.Item>
                            <Form.Item name="status" className="col-md-2">
                                <Select placeholder="Trạng thái" allowClear>
                                    {Object.keys(Config.statusAction).map((item) => {
                                        return <Select.Option value={item}>{Config.statusAction[item].label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="action_name" className="col-md-2">
                                <Select placeholder="Loại hành động" allowClear>
                                    {
                                        ListActionType.map((item) => {
                                            return <Select.Option value={item.key}>{item.value}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="log" className="col-md-2">
                                <Input placeholder="Nhập log cần tìm.." />
                            </Form.Item>
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="featuresButton mb-20 d-flex align-items-center">
                        <Button type="primary" className="mr-2" onClick={() => setVisibleForm({
                            ...visibleForm,
                            visible: true,
                            item: task,
                            viewDetail: true,
                            list_uids: task.list_uids,
                            list_groups: task.list_groups
                        })} disabled={!task}>Kịch bản</Button>
                        <Tooltip title="Xóa hành động đã chọn">
                            <Button
                                type="primary"
                                className="d-flex align-items-center"
                                icon={<DeleteOutlined />}
                                onClick={() => deleteMany(selectedRowKeys)}
                                danger
                            >
                                Xóa
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.total}
                        </Button>
                        <Button type="default" size="small" className="ml-2">
                            Đã chọn: {selectedRowKeys.length}
                        </Button>
                        <Tag className="ml-2 mr-0" color={"processing"}>
                            Đang xử lý: {task.num_target - (task.num_success + task.num_error)}
                        </Tag>
                        <Tag className="ml-2 mr-0" color={"success"}>
                            Thành công: {task.num_success}
                        </Tag>
                        <Tag className="ml-2 mr-0" color={"error"}>
                            Thất bại: {task.num_error}
                        </Tag>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                    </div>
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "Thiết bị / UID",
                                dataIndex: "uid",
                                render: (value, record) => {
                                    return <>
                                        {record.device_id}/{record.uid}
                                    </>
                                }
                            },
                            {
                                title: "Tập lệnh",
                                dataIndex: "action_type",
                                render: (value, record) => {
                                    let action_type = Platform.actions.find((item) => item.key == (record.options && record.options.actionName))
                                    return `${action_type && action_type.value}`
                                }
                            },
                            {
                                title: "Trạng thái",
                                dataIndex: "status",
                                render: (value, record) => {
                                    return <>
                                        <Tag color={Config.statusAction[value].color}>{Config.statusAction[value].label}</Tag>
                                    </>
                                }
                            },
                            {
                                title: "Thời gian chạy",
                                dataIndex: "last_time",
                                render: (value, record) => value ? moment(value).format("HH:mm DD/MM/YYYY") : null,
                            },
                            {
                                title: "Bắt đầu",
                                dataIndex: "start_time",
                                render: (value, record) => value ? moment(value).format("HH:mm DD/MM/YYYY") : (record.options && record.options.beginTime),
                            },
                            {
                                title: "log",
                                dataIndex: "logs",
                                render: (value, record) => {
                                    return value && value.length ? <div className="border p-2" style={{ maxHeight: "100px", overflow: "auto" }}>
                                        {
                                            value.map((item) => {
                                                return <p className="mb-2">
                                                    <a href={`/app/screen/?device_id=${record.device_id}&start_time=${item.time - (60 * 1000 * 5)}&end_time=${item.time + (60 * 1000 * 5)}`} className="link-danger">
                                                        {moment(item.time).format("HH:mm:ss DD/MM/YYYY")} - {item.log}
                                                    </a>
                                                </p>
                                            })
                                        }
                                    </div> : null
                                }
                            },
                            {
                                title: "Thao tác",
                                align: "right",
                                render: (value, record) => {
                                    return <React.Fragment>
                                        <Button type="primary" size="small" danger
                                            onClick={() => deleteMany([record._id])}
                                            className="ml-2"
                                            disabled={record.status != 1}
                                        >
                                            <i className="far fa-trash"></i>
                                        </Button>
                                    </React.Fragment>
                                }
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e),
                            getCheckboxProps: (record) => ({
                                disabled: record.status != 1
                            })
                        }}
                        rowKey="_id"
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            current: pagination.page,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100, 500],
                            onChange: (page, pageSize) => {
                                setpagination({
                                    limit: pageSize,
                                    page: page,
                                });
                            },
                            position: ["topRight"],
                            size: "small"
                        }}
                        loading={loading}
                        scroll={{
                            x: true
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(TaskAction);
