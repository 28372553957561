import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled, CopyFilled } from '@ant-design/icons';
import FileSaver from 'file-saver';

export default class exportUserFb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            loading: false,
            format_text: "uid | password"
        }
        this.form = null;
    }
    onExport = async (type) => {
        let data = '';
        if (!this.state.format_text) {
            this.props.notification({
                type: 'warning',
                message: 'Lỗi export',
                description: 'Vui lòng chọn định dạng file!'
            })
            return;
        }
        if (type == 'copy') {
            this.setState({ loading_copy: true });
        } else {
            this.setState({ loading: true });
        }
        let format = this.state.format_text.split(' | ');

        this.props.listData.forEach((item, i) => {
            if (!this.state.selectedAll && this.props.listIdChecked.indexOf(item._id) === -1) return;
            for (let ii = 0; ii < format.length; ii++) {
                if (format[ii].indexOf('.') == -1) {
                    data += item[format[ii]];
                } else if (item[format[ii].split('.')[0]]) {
                    data += item[format[ii].split('.')[0]][format[ii].split('.')[1]];
                }
                if (ii == format.length - 1) {
                    data += '\n';
                } else {
                    data += '|';
                }
            }
        })

        if (type == 'copy') {
            this.props.copyText(data);
            this.setState({ loading_copy: false });
        } else {
            let total = this.props.listData.length;
            var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
            setTimeout(() => {
                let date = new Date();
                FileSaver.saveAs(blob, `export-${total}-data-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.txt`);
                this.setState({ loading: false });
            }, 300)
        }
    };
    render() {
        return (
            <Modal
                title="Export"
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
            >
                <div className="export-option">
                    <div className="title">
                        <div style={{ display: "flex", alignItems: "center" }}><CheckOutlined className="mr-5" /> {this.props.listIdChecked.length} được chọn</div>
                    </div>
                    <div className="title">
                        <Button size={"small"} type="white" icon={this.state.selectedAll ? <DownSquareFilled /> : <DownSquareOutlined />} shape={'round'}
                            onClick={() => this.setState({ selectedAll: !this.state.selectedAll })}
                            className={this.state.selectedAll ? 'active' : ''}
                        >
                            Chọn toàn bộ theo bộ lọc hiện tại ({this.props.listData.length})
                        </Button>
                    </div>
                </div>
                <div className="export-container">
                    <div className="format mb-15" >
                        <Checkbox.Group value={this.state.format_text.replace(/\s/gi, "").split("|")} className="format mb-15" onChange={(e) => { this.setState({ format_text: e.join(' | ') }) }}>
                            <Row>
                                <Col span={8}>
                                    <Checkbox value="uid" style={{ lineHeight: '32px' }}>
                                        Uid
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="password" style={{ lineHeight: '32px' }}>
                                        Password
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="username" style={{ lineHeight: '32px' }}>
                                        Username
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="email" style={{ lineHeight: '32px' }}>
                                        Email
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="cookie" style={{ lineHeight: '32px' }}>
                                        Cookie
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="access_token" style={{ lineHeight: '32px' }}>
                                        Token
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="user_agent" style={{ lineHeight: '32px' }}>
                                        User agent
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="password_email" style={{ lineHeight: '32px' }}>
                                        Password Email
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="secret_2fa" style={{ lineHeight: '32px' }}>
                                        Secret 2fa
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="link_backup" style={{ lineHeight: '32px' }}>
                                        Link backup
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="proxy" style={{ lineHeight: '32px' }}>
                                        Proxy
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="friends_count" style={{ lineHeight: '32px' }}>
                                        Friends
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="log" style={{ lineHeight: '32px' }}>
                                        Log
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="url_login" style={{ lineHeight: '32px' }}>
                                        Url Login
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="full_name" style={{ lineHeight: '32px' }}>
                                        Full name
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="email_recover" style={{ lineHeight: '32px' }}>
                                        Email recover
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="full_name" style={{ lineHeight: '32px' }}>
                                        Full name
                                    </Checkbox>
                                </Col>
                                <Col span={8}>
                                    <Checkbox value="device_id" style={{ lineHeight: '32px' }}>
                                        Device
                                    </Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                        <Input disabled={true} value={this.state.format_text} />
                    </div>
                    <div className="export-header text-center d-flex align-items-center justify-content-center">
                        <Button type="primary" icon={<VerticalAlignBottomOutlined />} onClick={this.onExport} loading={this.state.loading}

                        >
                            Tải xuống
                        </Button>
                        <Button className="d-flex align-items-center ml-10" type="primary" icon={<CopyFilled />} onClick={() => this.onExport('copy')} loading={this.state.loading_copy}

                        >
                            Copy
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }
}
