import { ArrowLeftOutlined, DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Form, Input, Modal, notification, Select, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";
import { Config } from "../../../utils/config";

import { connect } from "react-redux"; 
import QueryString from "qs";
import WidgetDetailScript from "./Widget/DetailScript";

const TaskListAction = (props) => {
    const { roleTypeAdmin } = props;

    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [pagination, setpagination] = useState({
        limit: 20,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [ actionType, setactionType ] = useState("");
    const [ visibleDetailScript, setvisibleDetailScript ] = useState({
        visible: false,
        item: null
    });

    const [ initialValues, setinitialValues ] = useState(QueryString.parse(props.location.search.replace(/^\?/gi, "")));

    const refFormFilter = useRef();
    const timeoutSearch = useRef();

    const { Platform } = props.Access;

    useEffect(() => {
        if(refFormFilter.current){
            refFormFilter.current.setFieldsValue(initialValues)
        }
    }, [ initialValues, refFormFilter ])

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination
            }
            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }
            let response = await fetchData({
                url: roleTypeAdmin ? API_URL.admin.aciton.index : API_URL.aciton.list,
                params: params
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: roleTypeAdmin ? API_URL.admin.aciton.index : API_URL.aciton.delete,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    const ListActionType = useMemo(() => {
        return Platform.actions.filter((item) => !actionType || item.platform == actionType);
    }, [actionType, Platform])

    useEffect(() => {
        getListData();
    }, [pagination]);

    return (
        <React.Fragment>
            <WidgetDetailScript 
                {...visibleDetailScript}
                hide={() => setvisibleDetailScript({ visible: false })}
            />
            <div className="block">
                <Badge.Ribbon text="Auto" />
                <div className="block-content block-content-m">
                    <div className="d-flex align-items-center mb-20 ">
                        {
                            roleTypeAdmin && <Button className="d-flex align-items-center mr-2" size="small"
                            onClick={() => props.history.goBack()}
                        ><ArrowLeftOutlined /> Quay lại</Button>
                        }
                        <h2 className="block-title font-weight-bold">Tất cả các hành động</h2>
                    </div>
                    <div className="">
                        <Form
                            ref={refFormFilter}
                            className="row gx-10"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = e.keyword ? 300 : 0;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                }, timeout);
                            }}
                            onReset={() => {
                                setactionType(null);
                                getListData();
                            }}
                            initialValues={initialValues}
                        >
                            <Form.Item name="uid" className="col-md-2">
                                <Input placeholder="UID | tên đăng nhập" />
                            </Form.Item>
                            {
                                roleTypeAdmin ?
                                <Form.Item noStyle name="device_id" className="col-md-2">
                                    <Input placeholder="Thiết bị" type="hidden"/>
                                </Form.Item> : 
                                <Form.Item name="device_id" className="col-md-2">
                                    <Input placeholder="Thiết bị" />
                                </Form.Item>
                            }
                            <Form.Item name="status" className="col-md-2">
                                <Select placeholder="Trạng thái" allowClear>
                                    {Object.keys(Config.statusAction).map((item) => {
                                        return <Select.Option value={item}>{Config.statusAction[item].label}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name={"action_type"} className="col-md-2">
                                    <Select placeholder="Tập lệnh" allowClear onChange={setactionType}>
                                        {
                                            Platform.data.map((item) => {
                                                return <Select.Option value={item}>{item}</Select.Option>
                                            })
                                        }
                                        <Select.Option value={"remote_control"}>{"Remote Control"}</Select.Option>
                                    </Select>
                            </Form.Item>
                            <Form.Item name="action_name" className="col-md-2">
                                <Select placeholder="Loại hành động" allowClear>
                                    {
                                        ListActionType.map((item) => {
                                            return <Select.Option value={item.key}>{item.value}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.total}
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                        <Tooltip title="Xóa hành động đã chọn">
                            <Button
                                type="primary"
                                className="d-flex align-items-center ml-2"
                                icon={<DeleteOutlined />}
                                onClick={() => deleteMany(selectedRowKeys)}
                                danger
                                size="small"
                            >
                                Xóa
                            </Button>
                        </Tooltip>
                    </div>
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "Tập lệnh",
                                dataIndex: "action_type",
                                render: (value, record) => {
                                    return value == "remote_control" ? <>
                                        <a href="javascript:void(0)"
                                            onClick={() => setvisibleDetailScript({ visible: true, item: record.options })}
                                        >{value}</a>
                                    </> : value
                                }
                            },
                            {
                                title: "Uid/Action",
                                dataIndex: "action_type",
                                render: (value, record) => {
                                    let action_type = Platform.actions.find((item) => item.key == record.action_name)
                                    return `${record.uid}/${action_type ? action_type.value : record.action_name}`
                                }
                            },
                            {
                                title: "Device Id",
                                dataIndex: "device_id"
                            },
                            {
                                title: "Trạng thái",
                                dataIndex: "status",
                                render: (value, record) => {
                                    return <>
                                        <Tag color={Config.statusAction[value].color}>{Config.statusAction[value].label}</Tag>
                                    </>
                                }
                            },
                            {
                                title: "Thời gian chạy",
                                dataIndex: "last_time",
                                render: (value, record) => value && value > 10 ? moment(value).format("HH:mm DD/MM/YYYY") : null,
                            },
                            {
                                title: "Bắt đầu",
                                dataIndex: "start_time",
                                render: (value, record) => moment(record.start_time || record.created_time).format("HH:mm DD/MM/YYYY"),
                            },
                            {
                                title: "log",
                                dataIndex: "logs",
                                render: (value, record) => {
                                    return value && value.length ? <div className="border p-2" style={{ maxHeight: "100px", overflow: "auto" }}>
                                        {
                                            value.map((item) => {
                                                return <p className="mb-2">
                                                    <a href={`/app/screen/?device_id=${record.device_id}&start_time=${item.time - (60 * 1000 * 5)}&end_time=${item.time + (60 * 1000 * 5)}`} className="link-danger">
                                                        {moment(item.time).format("HH:mm:ss DD/MM/YYYY")} - {item.log}
                                                    </a>
                                                </p>
                                            })
                                        }
                                    </div> : null
                                }
                            },
                            {
                                title: "Thao tác",
                                align: "right",
                                render: (value, record) => {
                                    return <React.Fragment>
                                        <div className="">
                                            {
                                                record.task && !roleTypeAdmin && <Button type="primary" size="small" className=""
                                                    onClick={() => {
                                                        window.open(`/task_action/${record.task._id}?open_setting=1`)
                                                    }}
                                                >
                                                    Chiến dịch
                                                </Button>
                                            }
                                            <br></br>
                                            {
                                                record.is_post_data ? <Button 
                                                    type="primary" 
                                                    size="small"
                                                    className="mt-2"
                                                    onClick={() => {
                                                        window.open(`/action/data?action_id=${record._id}`)
                                                    }}
                                                >
                                                    Dữ liệu
                                                </Button> : null
                                            }
                                            <br></br>
                                            <Button type="primary" size="small" danger
                                                onClick={() => deleteMany([record._id])}
                                                className="mt-2"
                                                disabled={record.status != 1}
                                            >
                                                <i className="far fa-trash"></i>
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                }
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e),
                            getCheckboxProps: (record) => ({
                                disabled: record.status != 1
                            })
                        }}
                        rowKey="_id"
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            current: pagination.page,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100, 500],
                            onChange: (page, pageSize) => {
                                setpagination({
                                    limit: pageSize,
                                    page: page,
                                });
                            },
                            position: ["topRight"],
                            size: "small"
                        }}
                        loading={loading}
                        scroll={{
                            x: true
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(TaskListAction);
