import { DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, PauseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Alert, Badge, Button, Empty, Form, Input, Modal, notification, Popover, Select, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";

import { connect } from "react-redux";

import WidgetFormCreate from "./Widget/FormCreate";
import WidgetFormScriptRemote from "../../App/Widget/FormScriptRemote";
import { Config } from "../../../utils/config";
import WidgetTimerCalendar from "./Widget/ViewTimerCalendar";

const TaskAction = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [pagination, setpagination] = useState({
        limit: 20,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleForm, setVisibleForm] = useState({
        visible: false,
        item: null
    });
    const [visibleFormScriptRemote, setvisibleFormScriptRemote] = useState({
        visible: false,
        devices: [],
    });
    const [ visibleViewTimer, setvisibleViewTimer ] = useState({
        visible: false,
        data: []
    });

    const refFormFilter = useRef();
    const timeoutSearch = useRef();

    const platform = props.match.params.platform || "facebook";
    const { Access } = props;

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination,
                action_type: platform
            }
            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }
            let response = await fetchData({
                url: API_URL.taskAction.index,
                params: params
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const changeStatusTask = async (task_ids = [], is_running) => {
        try {
            setloading(true);
            let response = await fetchData({
                url: API_URL.taskAction.status,
                method: "put",
                body: JSON.stringify({
                    is_running,
                    ids: task_ids
                })
            });
            notification.success({
                message: "Thông báo",
                description: "Thao tác thành công!"
            });
            getListData();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: API_URL.taskAction.index,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    const listActionName = useMemo(() => {
        let data = Access.Platform.actions.filter((item) => item.platform == platform);
        return data;
    }, [platform, Access.Platform])

    const convertBadge = useMemo(() => {
        switch (platform) {
            case "facebook":
                return (
                    <>
                        <i class="fab fa-facebook-f"></i>
                        {` ${platform}`}
                    </>
                );
            case "instagram":
                return (
                    <>
                        <i class="fab fa-instagram"></i>
                        {` ${platform}`}
                    </>
                );
            case "kakaotalk":
                return "Kakao Talk";

            default:
                return "platform";
        }
    }, [platform])

    useEffect(() => {
        if (refFormFilter.current) {
            setData([]);
            setselectedRowKeys([]);
            refFormFilter.current.resetFields();
        }
    }, [platform])

    useEffect(() => {
        getListData();
    }, [pagination, platform]);

    return Access.Platform.data.length && Access.Platform.data.indexOf(platform) == -1 ? (
        <React.Fragment>
            <div className="block">
                <div className="block-content block-content-m pb-20">
                    <Empty description="Not platform"/>
                </div>
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <WidgetFormCreate
                {...visibleForm}
                listActionName={listActionName}
                hide={() => setVisibleForm({ visible: false })}
                onFinish={() => {
                    setVisibleForm({ visible: false });
                    getListData();
                }}
                platform={platform}
            />
            <WidgetFormScriptRemote
                {...visibleFormScriptRemote}
                onCancel={() => setvisibleFormScriptRemote({ visible: false, devices: [] })}
            />
            <WidgetTimerCalendar 
                {...visibleViewTimer}
                onCancel={() => setvisibleViewTimer({
                    visible: false
                }) }
            />

            <div className="block">
                <Badge.Ribbon text={convertBadge} />
                <div className="block-content block-content-m">
                    <h2 className="block-title mb-20 font-weight-bold">Quản lý hành động {platform}</h2>
                    <div className="">
                        <Form
                            ref={refFormFilter}
                            className="row gx-10"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = e.keyword ? 300 : 0;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                }, timeout);
                            }}
                            onReset={getListData}
                            initialValues={{
                                keyword: ""
                            }}
                        >
                            <Form.Item name="keyword" className="col-md-2">
                                <Input placeholder="Tìm kiếm.." />
                            </Form.Item>
                            <Form.Item name="status" className="col-md-2">
                                <Select placeholder="Chọn trạng thái" allowClear>
                                    {
                                        Object.keys(Config.statusTaskAction).map((key) => {
                                            return <Select.Option value={key}>{Config.statusTaskAction[key].label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                            <Button
                                type="default"
                                className="ml-2 d-flex align-items-center col-auto"
                                onClick={getListData}
                            >
                                <i className="si si-reload mr-2"></i> làm mới
                            </Button>
                        </Form>
                    </div>
                    {/* <div className="featuresButton mb-20 d-flex">
                        <Button
                            type="primary"
                            className="d-flex align-items-center mr-2"
                            icon={<PlusOutlined />}
                            onClick={() => setVisibleForm({ visible: true })}
                        >
                            Thêm mới
                        </Button>
                    </div> */}
                    <div className="mb-0 d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.total}
                        </Button>
                        <Button type="default" size="small" className="ml-2">
                            Đã chọn: {selectedRowKeys.length}
                        </Button>
                        <Tooltip title="Xóa chiến dịch đã chọn">
                            <Button
                                type="primary"
                                className="d-flex align-items-center ml-2"
                                icon={<DeleteOutlined />}
                                onClick={() => deleteMany(selectedRowKeys)}
                                danger
                                size="small"
                            >
                                Xóa
                            </Button>
                        </Tooltip>
                        <Popover
                            trigger={"click"}
                            placement="bottomLeft"
                            content={<div>
                                <p className="mb-2">
                                    <a href="javascript:void(0)"
                                        onClick={() => changeStatusTask(selectedRowKeys, false)}
                                    >Tạm dừng</a>
                                </p>
                                <p className="mb-0">
                                    <a href="javascript:void(0)"
                                        onClick={() => changeStatusTask(selectedRowKeys, true)}
                                    >Tiếp tục</a>
                                </p>
                            </div>}
                        >
                            <Button
                                    type="primary"
                                    className="d-flex align-items-center ml-2" 
                                    // icon={<PauseCircleFilled />}
                                    size="small"
                                    disabled={!selectedRowKeys.length}
                                >
                                    Stop/Pause chiến dịch
                            </Button>
                        </Popover>
                    </div>
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "Tên",
                                dataIndex: "name",
                                render: (value, record) => {
                                    let timer = (record.timer || []).sort((a, b) => a-b);
                                    let timer_run = timer.filter((a) => a >= Date.now());

                                    return <div style={{maxWidth: 400}}>
                                        <div>{value}</div>
                                        {
                                            timer.length ? <>
                                                <Button type="primary" danger={timer_run.length} className="mt-2" size="small"
                                                    onClick={() => {
                                                        setvisibleViewTimer({
                                                            visible: true,
                                                            data: timer,
                                                            task: record
                                                        })
                                                    }}
                                                >
                                                    <i className="far fa-clock mr-2"></i> {timer_run.length}
                                                </Button>
                                            </> : null
                                        }
                                    </div>
                                }
                            },
                            {
                                title: "Loại",
                                dataIndex: "action_type",
                                render: (value, record) => {
                                    let action_type = Access.Platform.actions.find((item) => item.key == (record.options && record.options.actionName))
                                    return `${value} - ${action_type ? action_type.value : (record.options && record.options.actionName)}`
                                }
                            },
                            {
                                title: "Thiết bị",
                                dataIndex: "device_ids",
                                align: "center",
                                render: (value, record) => {
                                    return value && value.length && <Tooltip title="Tạo lệnh remote cho thiết bị">
                                        <Button type="link" primary
                                            onClick={() => setvisibleFormScriptRemote({ visible: true, devices: value })}
                                        >
                                            {value.length} / {record.device_groups.length} box
                                        </Button>
                                    </Tooltip>
                                }
                            },
                            {
                                title: "Trạng thái",
                                dataIndex: "num_target",
                                render: (value, record) => {
                                    return <>
                                        <Tag color={Config.statusTaskAction[record.status].color}>
                                            {Config.statusTaskAction[record.status].label}
                                        </Tag>
                                        <span className="ml-1 mr-1"></span>
                                        <span className="text-success">{record.num_success}</span> /
                                        <Tooltip title="Lỗi">
                                            <span className="text-danger">{record.num_error}</span> /
                                        </Tooltip>
                                        <Tooltip title="Tổng số hành động">
                                            <span>{record.num_target}</span>
                                        </Tooltip>
                                    </>
                                }
                            },
                            {
                                title: "Ngày tạo",
                                dataIndex: "created_time",
                                render: (value) => moment(value).format("HH:mm DD/MM/YYYY"),
                            },
                            {
                                title: "Thao tác",
                                align: "right",
                                render: (value, record) => {
                                    return <React.Fragment>
                                        <div>
                                            <Tooltip title="Chi tiết các hành động">
                                                <Button type="primary" size="small"
                                                    onClick={() => {
                                                        props.history.push(`/task_action/${record._id}`)
                                                    }}
                                                    className="mb-2"
                                                >
                                                    <i className="far fa-eye"></i>
                                                </Button>
                                            </Tooltip>
                                            <br></br>
                                            <Tooltip title="Chi tiết cấu hình">
                                                <Button type="primary" size="small"
                                                    onClick={() => {
                                                        setVisibleForm({ 
                                                            visible: true, 
                                                            item: record, 
                                                            viewDetail: true,
                                                            list_uids: record.list_uids,
                                                            device: {
                                                                device_ids: record.device_ids,
                                                                device_groups: record.device_groups
                                                            }
                                                        });
                                                    }}
                                                    className="mb-2"
                                                >
                                                    <i className="far fa-cog"></i>
                                                </Button>
                                            </Tooltip>
                                            <br></br>
                                            <Tooltip title="Copy hành động">
                                                <Button type="primary" size="small"
                                                    onClick={() => {
                                                        setVisibleForm({ 
                                                            visible: true, 
                                                            item: record, 
                                                            isCopy: true,
                                                            list_uids: record.list_uids,
                                                            device: {
                                                                device_ids: record.device_ids,
                                                                device_groups: record.device_groups
                                                            }
                                                        });
                                                    }}
                                                    className="mb-2"
                                                >
                                                    <i className="far fa-copy"></i>
                                                </Button>
                                            </Tooltip>
                                            <br></br>
                                            <Button type="primary" size="small" danger
                                                onClick={() => deleteMany([record._id])}
                                                className=""
                                            >
                                                <i className="far fa-trash"></i>
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                }
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e),
                        }}
                        rowKey="_id"
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            current: pagination.page,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100, 500],
                            onChange: (page, pageSize) => {
                                setpagination({
                                    limit: pageSize,
                                    page: page,
                                });
                            },
                            position: ["topRight"],
                            size: "small"
                        }}
                        loading={loading}
                        scroll={{
                            x: true
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(TaskAction);
