import React, { useEffect, useMemo, useState } from "react";

import { Form, Input, TreeSelect, InputNumber, Switch, Upload, message, Radio } from "antd";

import WidgetUpload from "./UploadFile";

const WidgetFormItem = (props) => {
    const [checked, setChecked] = useState(false);

    const { folderContent, item, viewDetail } = props;

    const FormFolderContent = useMemo(() => {
        return <React.Fragment>
            <Form.Item
                label="Folder nội dung"
                tooltip={"Folder nội dung ứng với các folder ở màn quản lý content"}
                name="contentFolder"
                rules={[
                    {
                        required: true,
                        message: "Vui lòng chọn folder chứa content!",
                    },
                ]}
            >
                <TreeSelect
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                    }}
                    treeData={folderContent}
                    placeholder="Hãy chọn một folder"
                    treeDefaultExpandAll={false}
                />
            </Form.Item>
        </React.Fragment>
    }, [item, folderContent])

    const FormPostContent = useMemo(() => {
        return <Form.Item
            label="Nội dung bài đăng"
            name="postContent"
            rules={[
                {
                    required: true,
                    message: "Vui lòng tải lên nội dung post!",
                },
            ]}
        >
            <Input.TextArea
                rows={3}
                allowClear={true}
                placeholder="..."
            />
        </Form.Item>
    }, [item])

    useEffect(() => {
        if(item){
            setChecked(item.options.switchOption);
        } else{
            setChecked(false);
        }
    }, [item])

    return <React.Fragment>
        {
            viewDetail ?
                <>
                    {
                        item.options.switchOption ?
                            <React.Fragment>
                                {FormPostContent}
                                <WidgetUpload label="Ảnh trong bài đăng" disabled={true} fileList={item.options.postImage && item.options.postImage.fileList} />
                            </React.Fragment>
                            : <React.Fragment>
                                {FormFolderContent}
                            </React.Fragment>
                    }
                </> :
                <>
                    <Form.Item
                        label="Tùy chọn nội dung"
                        tooltip={"Chọn từ thư viện: random 1 trong số các nội dung trong folder đã chọn"}
                        name="switchOption"
                        extra={
                            checked
                                ? "** Nhập nội dung thủ công **"
                                : "** sử dụng nội dung có sẵn từ thư viện **"
                        }
                    >
                        <Switch
                            onChange={(checked) => {
                                setChecked(checked);
                            }}
                            checked={checked}
                        />
                        {/* <Radio.Group >
                            <Radio value={false}>Sử dụng nội dung từ thư viện</Radio>
                            <Radio value={true}>Nhập nội dung thủ công</Radio>
                        </Radio.Group> */}
                    </Form.Item>

                    {checked ? (
                        <React.Fragment>
                            {FormPostContent}

                            <WidgetUpload label="Ảnh trong bài đăng" fileList={item && item.options.postImage && item.options.postImage.fileList}/>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {FormFolderContent}
                        </React.Fragment>
                    )}
                </>
        }
    </React.Fragment>
}

export default WidgetFormItem;