import React, { Component } from 'react';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled } from '@ant-design/icons';

export default class InfoUserFb extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.form = null;
    }
    render() {
        let logs = this.props.user.logs || [];
        logs.sort((a, b) => b.time - a.time);
        logs = logs.map((log) => `${new Date(log.time)} ---> ${log.log} \n` );
        return (
            <Modal
                title="Thông tin tài khoản"
                visible={this.props.visible}
                onCancel={this.props.hide}
                footer={null}
                width={660}
            >
                <div className="form-row">
                    <div className="form-group col-6">
                        <label>Full Name</label>
                        <input value={this.props.user.full_name} className="form-control" />
                    </div>
                    <div className="form-group col-6">
                        <label>User Name</label>
                        <input value={this.props.user.username} className="form-control" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-6">
                        <label>UID</label>
                        <input value={this.props.user.uid} className="form-control" />
                    </div>
                    <div className="form-group col-6">
                        <label>Password</label>
                        <input value={this.props.user.password} className="form-control" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-6">
                        <label>Password Email</label>
                        <input value={this.props.user.password_email} className="form-control" />
                    </div>
                    <div className="form-group col-6">
                        <label>Email</label>
                        <input value={this.props.user.email} className="form-control" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-6">
                        <label>Cookie</label>
                        <input value={this.props.user.cookie} className="form-control" />
                    </div>
                    <div className="form-group col-6">
                        <label>Token</label>
                        <input value={this.props.user.access_token} className="form-control" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-6">
                        <label>User agent</label>
                        <input value={this.props.user.user_agent} className="form-control" />
                    </div>
                    <div className="form-group col-6">
                        <label>Secret 2fa</label>
                        <input value={this.props.user.secret_2fa} className="form-control" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-6">
                        <label>Link backup</label>
                        <input value={this.props.user.link_backup} className="form-control" />
                    </div>
                    <div className="form-group col-6">
                        <label>Proxy</label>
                        <input value={this.props.user.proxy} className="form-control" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label>Device</label>
                        <input value={this.props.user.device_id} className="form-control" />
                    </div>
                </div>
                <div className="form-group">
                    <label>Log</label>
                    <textarea value={logs.join('\n')} className="form-control" style={{minHeight: "150px"}}/>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label>Url Login</label>
                        <input value={this.props.user.url_login} className="form-control" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label>Email recover</label>
                        <input value={this.props.user.email_recover} className="form-control" />
                    </div>
                </div>
            </Modal>
        )
    }
}
