import React, { Component, useEffect, useRef, useState } from 'react';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Switch, Table, Spin, Tag } from 'antd';
import { fetchData, formatNumber } from '../../lib/helpers';
import { Footer, Header, Sidebar } from '../../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import qs from 'qs';
import { connect } from 'react-redux';

const UserView = (props) => {
    const { Access } = props;
    
    const [data, setData] = useState({
        listData: [],
        total: 0,
    })

    const [loading, setloading] = useState(false);
    const [loadingForm, setloadingForm] = useState(false);
    const [visibleForm, setvisibleForm] = useState(false);
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20
    });

    const formFilter = useRef();
    const form = useRef();
    const timeoutfilter = useRef();

    const levels = {
        1: "Người dùng",
        5: "Get acc",
        90: "Acc trash",
        99: "Admin",
        // 999: "Root"
    };

    const status = {
        0: {
            label: "Chưa kích hoạt"
        },
        1: {
            label: "Hoạt động",
            color: "green"
        },
        2: {
            label: "Tạm khóa",
            color: "red"
        }
    }

    useEffect(() => {
        getListData();
    }, [pagination])

    const getListData = async () => {
        try {
            setloading(true);

            let params = {
                ...pagination
            }
            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };
            let response = await fetchData({
                url: `api/v1/admin/user/list?${qs.stringify(params)}`,
                method: 'get'
            });

            setData({ listData: response.data, total: response.total })

        } catch (err) {
            props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        } finally {
            setloading(false);
        }
    }

    const save = async (values) => {
        try {
            setloadingForm(true);

            if(values['platforms']){
                values['role'] = {
                    platforms: values.platforms
                }
            }

            let response = await fetchData({
                url: `api/v1/admin/user/${values['_id']}`,
                method: 'put',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });

            props.message({ content: "Thao tác thành công", type: 'success' });

            getListData();

            setvisibleForm(false);
        } catch (err) {
            props.message({ content: err.message || 'Đã cõ lỗi xảy ra', type: 'error' });
        } finally {
            setloadingForm(false);
        }
    }
    return (
        <React.Fragment>
            <div className="row ">
                <Modal
                    title="Cập nhập người dùng"
                    visible={visibleForm}
                    onCancel={() => {
                        setvisibleForm(false);
                        form.current.resetFields()
                    }}
                    footer={null}
                >
                    <Form
                        ref={form}
                        onFinish={save}
                        initialValues={{

                        }}
                    >
                        <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="level">
                            <Select placeholder="Level">
                                {
                                    Object.keys(levels).map((key) => {
                                        return <Select.Option value={parseInt(key)}>{levels[key]}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="status">
                            <Select placeholder="Status">
                                {
                                    Object.keys(status).map((key) => {
                                        return <Select.Option value={parseInt(key)}>{status[key].label}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="platforms">
                            <Select placeholder="Chọn nền tảng" mode="multiple">
                                {
                                    Access.Platform.data.map((item) => {
                                        return <Select.Option value={item}>{item}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <div className="d-flex justify-content-end" >
                            <Button type="primary" htmlType="submit" loading={loadingForm}>Cập nhập</Button>
                        </div>
                    </Form>
                </Modal>
                <div className="col-md-12 ">
                    <div className="block">
                        <div className="block-content block-content-m">
                            <div className="row mb-20">
                                <div className="col-md-9">
                                    <button className="btn btn-info btn-sm">Tất cả ({data.total})</button>
                                    <button className="btn btn-primary btn-sm ml-2" onClick={() => getListData()}>
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                            <Form
                                ref={formFilter}
                                initialValues={{

                                }}
                                className="row"
                            >
                                <Form.Item name="keyword" className="col-md-2">
                                    <Input placeholder="Tìm kiếm.." onChange={() => {
                                        if (timeoutfilter.current) clearTimeout(timeoutfilter.current);
                                        timeoutfilter.current = setTimeout(() => {
                                            getListData('reset');
                                        }, 300)
                                    }} />
                                </Form.Item>
                                <Form.Item name="level" className="col-md-2">
                                    <Select onChange={() => getListData('reset')} placeholder="level">
                                        {
                                            Object.keys(levels).map((key) => {
                                                return <Select.Option value={parseInt(key)}>{levels[key]}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item name="platforms" className="col-md-2">
                                    <Select placeholder="Chọn nền tảng"
                                        onChange={() => getListData('reset')}
                                    >
                                        {
                                            Access.Platform.data.map((item) => {
                                                return <Select.Option value={item}>{item}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item className="col-md-1">
                                    <Button htmlType="reset" type="primary" onClick={(e) => {
                                        formFilter.current.resetFields();
                                        getListData('reset');
                                    }}>Xóa lọc</Button>
                                </Form.Item>
                            </Form>
                            <div className="row ">
                                <div className="col-md-12 ">
                                    <Spin spinning={loading}>
                                        <Table
                                            columns={[
                                                {
                                                    title: "#",
                                                    key: "index",
                                                    render: (value, item, index) => {
                                                        if (pagination.page == 1) {
                                                            return index + 1;
                                                        } else {
                                                            return (pagination.page - 1) * pagination.limit + 1
                                                        }
                                                    }
                                                },
                                                {
                                                    title: "Full name",
                                                    key: "full_name",
                                                    dataIndex: "full_name",
                                                    render: (item, record) => {
                                                        return <>
                                                            <p className='mb-1'>{item}</p>
                                                            <p className='mn-0'><Tag>{levels[record.level]}</Tag></p>
                                                        </>
                                                    }
                                                },
                                                {
                                                    title: "User name",
                                                    key: "user_name",
                                                    dataIndex: "user_name"
                                                },
                                                {
                                                    title: "Status",
                                                    key: "status",
                                                    dataIndex: "status",
                                                    render: (item) => status[item] && <Tag color={status[item].color}>{status[item].label}</Tag>
                                                },
                                                {
                                                    title: "Platform",
                                                    dataIndex: "role",
                                                    render: (item) => {
                                                        return item && item.platforms.join(", ")
                                                    }
                                                },
                                                {
                                                    title: "Last time",
                                                    key: "last_time",
                                                    dataIndex: "last_time",
                                                    render: (item) => item ? moment(item).format('HH:mm DD/MM/YYYY') : ""
                                                },
                                                {
                                                    title: "Created time",
                                                    key: "created_time",
                                                    dataIndex: "created_time",
                                                    render: (created_time) => created_time ? moment(created_time).format('HH:mm DD/MM/YYYY') : ""
                                                },
                                                {
                                                    title: "Action",
                                                    key: "action",
                                                    className: "text-right",
                                                    render: (item) => {
                                                        return <div>
                                                            <Tooltip title="Chỉnh sửa">
                                                                <a href="#" className="btn btn-primary ml-5 p-5" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setvisibleForm(true);
                                                                    setTimeout(() => {
                                                                        if (form){
                                                                            form.current.setFieldsValue({
                                                                                ...item
                                                                            })
                                                                            if(item.role){
                                                                                form.current.setFieldsValue({
                                                                                    "platforms": item.role.platforms
                                                                                })
                                                                            }
                                                                        }
                                                                    }, 50);
                                                                }}>
                                                                    <i class="far fa-edit"></i>
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                }
                                            ]}
                                            dataSource={data.listData}
                                            pagination={{
                                                total: data.total,
                                                page: pagination.page,
                                                pageSize: pagination.limit,
                                                onChange: (current, pageSize) => {
                                                    setpagination({
                                                        page: current,
                                                        limit: pageSize
                                                    })
                                                }
                                            }}

                                        />
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({
    
})

export default connect(mapStateToProps, mapDispathToProps)(UserView);