import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Pagination, InputNumber, Spin, DatePicker, Table, Radio } from 'antd';
import { fetchData } from '../lib/helpers';
import {
    Footer, Header, Sidebar, ModalUploadUserFb, ModalExportUserFb, FormSaveFolder, PopupMenu, SpinLoading, FormCheckUid,
    FormMoveData, SyncMcare, SyncFbaseToMcare, FormScriptLogin, FormMoveDataToFolder, FormMoveToUser, FormCreateScript, ResultExportFileBackup,
    FormGetCookie, AlertStop, ScriptCheckAds, CheckPriceAds, InfoUserFb, FormScriptAddMail, FormAddUs
} from '../component';

import CheckLiveInstagramComponent from "./Action/components/CheckLiveDieInstagram";
import ChangeDeviceIdComponent from "./Action/components/ChangeDeviceId";

import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import extfbase from '../lib/conector.js'
import { AccountMetaData } from '../lib/account';
import moment from 'moment';
import FileSaver from 'file-saver';
import qs from 'qs';

import locale from 'antd/es/date-picker/locale/vi_VN';
import WidgetTable from "./Home/Widget/table";
import QueryString from 'qs';
import HomeCreateActionWidget from './Home/Widget/createAction';
import { connect, useSelector } from 'react-redux';
import ButtonCreateAction from './Home/Widget/buttonCreateAction';
import cx from "classnames";
import WidgetLogAction from './Home/Widget/logAction';

const { SubMenu } = Menu;
const { Option } = Select;
const { DirectoryTree } = Tree;

class HomeView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.rootSubmenuKeys = [];
        this.state = {
            openKeys: [],
            currentFolder: 0,
            folderDataParse: [],
            listData: [],
            folderData: [],
            listIdChecked: [],
            listUidChecked: [],
            valuesFilter: {},
            popup: {},
            loading: false,
            dataMenuRight: null,
            DataM: {},
            keyFileFilter: Date.now(),
            limit: 20,
            totalData: 0,
            initialValues: {
                status: "",
                email_status: "",
                status_ads: "",
                is_cookie: "",
                status_add_mail: "",
                status_sync_us: "",
                ...QueryString.parse(props.location.search.replace(/^\?/gi, ""))
            },
            visibleCreateAction: {
                visible: false
            },
            listDeviceGroup: [],
            visibleLogAction: {
                visible: false
            }
        };
        this.limit = 20;
        this.page = 1;
        this.nextpage = true;
        this.timeOutFilter = null;
        this.nick_run = 0;
        this.nick_checkpoint = 0;
    }
    // componentDidMount = () => {
    //     console.log("sss")
    // }
    componentDidMount = async () => {
        this.getListFolder();
        this.getListDeviceGroup();
        this.getListData();
        var _this = this;
        window.addEventListener("click", function () {
            if (_this.state.popup && _this.state.popup['visible']) {
                _this.setState({
                    popup: {}
                })
            }
        }, false);

        try {
            await extfbase.init({
                ver_ext: this.props.setting ? this.props.setting.ver_ext : '1.0.0'
            });
            window.extfbase = extfbase;
            window.extfbase.port.onMessage.addListener(async function (message) {
                try {
                    console.log('message', message);
                    if (message.method == 'log_action') {
                        if (message.id_ufb) {
                            let findIndex = _this.state.listData.findIndex((item) => item._id == message.id_ufb);
                            if (findIndex > -1) {
                                _this.state.listData[findIndex]['log'] = message.log_action || message.log || message.message;
                                if (_this.state.listData[findIndex]['logs']) {
                                    _this.state.listData[findIndex]['logs'].push({
                                        log: _this.state.listData[findIndex]['log'],
                                        time: Date.now()
                                    });
                                }
                                if (message.code) {
                                    _this.state.listData[findIndex]['status'] = message.code;
                                }
                                _this.setState({ reload: !_this.state.reload });
                            }
                        }
                    }
                    if (message.method == 'get_cookie_ext') {
                        let dataRunGetCookie = _this.state.dataRunGetCookie;
                        if ((message.type == 'log' || message.type == 'result') && message.data) {
                            let findIndex = _this.state.listData.findIndex((item) => item._id == message.data._id);
                            if (findIndex > -1) {
                                let log_messages = _this.state.listData[findIndex]['log_messages'];
                                if (!log_messages) {
                                    _this.state.listData[findIndex]['log_messages'] = {
                                        message: message.log_action || message.data.log || message.data.message,
                                        total: 1
                                    }
                                } else {
                                    _this.state.listData[findIndex]['log_messages'] = {
                                        message: message.log_action || message.data.log || message.data.message,
                                        total: log_messages['total'] + 1
                                    }
                                }
                                if (message.data.code) {
                                    _this.state.listData[findIndex]['status'] = message.data.code;
                                }
                                _this.state.listData[findIndex]['log'] = message.log_action || message.data.log || message.data.message;
                                _this.setState({ reload: !_this.state.reload });
                            }
                        }
                        if (message.data && dataRunGetCookie && message.type == 'result') {
                            dataRunGetCookie['run'] -= 1;

                            if (message.data.code == 1) {
                                dataRunGetCookie['success'] += 1;
                            } else if (message.data.code == 4) {
                                dataRunGetCookie['die'] += 1;
                            } else if (message.data.code == 3) {
                                dataRunGetCookie['checkpoint'] += 1;
                                _this.nick_checkpoint = _this.nick_checkpoint + 1;
                            } else if (message.data.code == 2) {
                                dataRunGetCookie['error'] += 1;
                            } else if (message.data.code == 6) {
                                dataRunGetCookie['other'] += 1;
                            }

                            if (_this.max_nick_checkpoint_type == 2 && message.data.code != 3) {
                                _this.nick_checkpoint = 0;
                            }

                            await _this.saveLogGetCookie({ data: dataRunGetCookie });

                            if (dataRunGetCookie['run'] <= 0) {
                                _this.setState({
                                    dataRunGetCookie: dataRunGetCookie,
                                    // run_get_cookie: _this.state.typeScriptActionExt != "get_cookie" ? _this.state.run_get_cookie : false
                                }, () => {
                                    _this.nick_checkpoint = 0;
                                })
                            } else {
                                _this.setState({
                                    dataRunGetCookie: dataRunGetCookie
                                })
                            }
                        }
                    }
                    if (message.method == 'close_tab_get_cookie') {
                        let dataRunGetCookie = _this.state.dataRunGetCookie;
                        dataRunGetCookie['pending'] -= 1;
                        _this.setState({
                            reload: !_this.state.reload
                        })
                    }

                    if (message.method == "continue_get_cookie") {
                        console.log('continue get cookie :))');
                        let dataRunGetCookie = _this.state.dataRunGetCookie;
                        let btn_get_cookie = document.querySelector('#btn-get-cookie');

                        if (!_this.state.run_get_cookie && dataRunGetCookie && btn_get_cookie) {
                            _this.setState({
                                visibleStop: false
                            }, () => {
                                btn_get_cookie.click();
                            })
                        }
                    }

                    if (message.method == "reset_get_cookie") {
                        console.log('reset get cookie...');
                        let btn_get_cookie = document.querySelector('#btn-get-cookie');
                        if (btn_get_cookie && !_this.state.run_get_cookie) {
                            _this.setState({
                                visibleStop: false
                            }, () => {
                                btn_get_cookie.click();
                                console.log('click get cookie');
                            })
                        }
                    }

                    if (message.method == "stop_get_cookie") {
                        console.log('stop get cookie...');
                        let btn_stop_get_cookie = document.querySelector('#btn-stop-get-cookie');
                        if (btn_stop_get_cookie && _this.state.run_get_cookie) {
                            btn_stop_get_cookie.click();
                            console.log('click stop cookie');
                        }
                    }

                } catch (err) {
                    console.log(err);
                }
            })
        } catch (err) {
            console.log('conect ext --------> ', err);
        }
    }
    parseMenu = (list) => {
        var map = {}, node, i;
        var roots = [
            {
                title: 'Tất cả',
                key: 0,
                value: 0
            }
        ]

        for (i = 0; i < list.length; i++) {
            map[list[i].folder_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.folder_name;
            node['key'] = node.folder_id;
            node['value'] = node.folder_id;
            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    console.log(node);
                }
            } else {
                this.rootSubmenuKeys.push('folder_' + node.folder_id);
                roots.push(node);
            }
        }
        return roots;
    }
    renderMenu = (menu) => {
        if (menu.children !== undefined && menu.children.length > 0) {
            return <SubMenu
                key={`folder_${menu.folder_id}`}
                title={
                    <span className="head-menu" data-id={menu.folder_id} onContextMenu={(e) => this.handleContextMenu(e, menu)}>
                        <FolderOpenOutlined />
                        <span>{menu.folder_name}</span>
                    </span>
                }
            >
                <Menu.Item key={menu.folder_id} style={{ display: "none" }}>
                </Menu.Item>
                {
                    menu.children.map(data => {
                        return this.renderMenu(data);
                    })
                }
            </SubMenu>
        } else {
            return <Menu.Item key={menu.folder_id}>
                <span className="head-menu" data-id={menu.folder_id} onContextMenu={(e) => this.handleContextMenu(e, menu)}>
                    <span>{menu.folder_name}</span>
                </span>
            </Menu.Item>
        }
    }
    handleChangeFolder = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys, currentFolder: latestOpenKey ? latestOpenKey.replace('folder_', '') : this.state.currentFolder }, () => {
                this.getListData('reset');
            });
        } else {
            this.setState({
                openKeys: openKeys ? [latestOpenKey] : [],
                currentFolder: latestOpenKey ? latestOpenKey.replace('folder_', '') : this.state.currentFolder
            }, () => {
                this.getListData('reset');
            });
        }
    };
    handleClickFolder = e => {
        this.setState({
            currentFolder: e.key
        }, () => {
            this.getListData('reset');
        })
    }
    uploadUserFbDone = (data) => {
        this.setState({
            visibleModalUpload: false
        }, () => {
            // this.props.notification({
            //     message: 'Import thành công',
            //     description: `Cập nhập thành công ${data.nUpserted} đối tượng mới, và ${data.nMatched} cũ`,
            //     type: 'success'
            // })
            this.getListData('reset');
        })
    }
    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
                this.nextpage = false;
            }
            this.setState({ loading: true, listIdChecked: [], listUidChecked: [] });
            let query = `limit=${this.limit}&page=${this.page}`;

            let valuesFilter = { ...this.state.valuesFilter };
            valuesFilter['folder_id'] = this.state.currentFolder;
            if (valuesFilter['uids']) valuesFilter['uids'] = valuesFilter['uids'].replace(/\s/gi, '').split(',');
            if (this.formFilter) {
                valuesFilter = {
                    ...valuesFilter,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let res = await fetchData({
                url: `api/v1/user_fb/list?${query}`,
                method: 'post',
                body: JSON.stringify(valuesFilter),
                headers: { 'Content-Type': 'application/json' }
            });
            if (this.limit != res.data.length) {
                this.nextpage = false;
            } else {
                this.nextpage = true;
            }
            let data = res.data;
            data.map((item) => {
                item.folder_name = this.findFolderName(item.folder_id);
                return item;
            })
            // let listData = type == 'reset' ? res.data : this.state.listData.concat(res.data);
            this.setState({ listData: data, totalData: res.total, loading: false });
        } catch (err) {
            this.setState({ loading: false });
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    getListFolder = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/folder/list`,
            });
            this.setState({ folderData: res.data, folderDataParse: this.parseMenu(res.data) });
        } catch (err) {
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    getListDeviceGroup = async () => {
        try {
            let res = await fetchData({
                url: `api/v2/app/profile/group`,
            });
            this.setState({ listDeviceGroup: res.data });
        } catch (err) {
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    renderHtmlData = (data) => {

    }
    handleScroll = (e) => {
        return;
        if (
            e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop &&
            this.nextpage
        ) {
            this.page += 1;
            this.nextpage = false;
            e.target.scrollTop -= 50;
            this.getListData();
        }
    }
    findFolderName = (folder_id) => {
        let find = this.state.folderData.find((e) => e.folder_id === folder_id);
        if (find) return find.folder_name;
        return '';
    }
    removeMany = async (values) => {
        var { id, type, list_ids } = values;
        if (type == 1) {
            list_ids = [id];
        }
        if (list_ids.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Lỗi xóa',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${list_ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/user_fb/delete_many',
                            method: 'post',
                            body: JSON.stringify({ listids: list_ids.join(',') }),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        notification({
                            type: 'success',
                            message: 'Xóa thành công',
                            description: `Bạn đã xóa thành công ${list_ids.length} đối tượng!`
                        })
                        // if (type == 2) _this.setState({ listIdChecked: [] });
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Lỗi xóa',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    saveFolderDone = (data) => {
        this.setState({
            visibleModalFormFolder: false,
            currentFolder: data.folder_id
        }, () => {
            this.props.notification({
                message: 'Thêm folder',
                description: `Thêm folder "${data.folder_name}" thành công`,
                type: 'success'
            })
            this.getListData('reset');
            this.getListFolder();
        })
    }
    removeFolder = async (folder_id) => {
        let folder = this.state.folderData.find((e) => e.folder_id === parseInt(folder_id));
        if (!folder) {
            return;
        }

        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa thư mục "${folder.folder_name}". Chú ý, thao tác này sẽ xóa hết dữ liệu và các thư mục con.`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/folder/delete/' + folder.folder_id,
                            method: 'delete'
                        });
                        notification({
                            type: 'success',
                            message: 'Xóa folder thành công',
                            description: `Bạn đã xóa thành công`
                        })
                        _this.setState({
                            currentFolder: folder.parent_id
                        })
                        _this.getListData('reset');
                        _this.getListFolder();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Lỗi xóa',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 300)
    }
    handleFilter = (e) => {
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter }, () => { this.getListData('reset') });
    }
    handleContextMenu = (e, folder = null) => {
        this.setState({ dataMenuRight: { type: 1, folder: folder } });
        this.setPosContextmenu(e);
    }
    handleContextMenuTable = (e, item) => {
        this.setState({ dataMenuRight: { type: 2, item: item } });
        this.setPosContextmenu(e);
    }
    setPosContextmenu = (e) => {
        e.preventDefault();
        console.log(e.clientY)
        let popup = {
            visible: true,
            x: e.clientX
        }
        if (window.innerHeight - e.clientY < 400) {
            popup['bt'] = window.innerHeight - e.clientY;
        } else {
            popup['y'] = e.clientY;
        }
        this.setState({
            popup: popup
        })
    }
    saveFolder = (data) => {
        this.setState({ visibleModalFormFolder: data });
    }
    moveData = async (data) => {
        let loading = message.loading('Action in progress..', 0);
        try {
            let { action, folder_id, to_folder_id, list_ids } = data;
            let result = await fetchData({
                url: 'api/v1/data/move',
                method: 'post',
                body: JSON.stringify({ action: action, folder_id: folder_id, to_folder_id: to_folder_id, list_ids: list_ids }),
                headers: { 'Content-Type': 'application/json' }
            });
            setTimeout(loading, 0);
            this.state.copyMenuRight = null;
            document.querySelectorAll('[name="check_item"]:checked').forEach((item) => {
                item.checked = false;
            })

            this.getListFolder();
            this.getListData();

            this.props.notification({
                message: 'Di chuyển dữ liệu',
                description: `Di chuyển thành công ${result.data} tài khoản`,
                type: 'success'
            })
        } catch (err) {
            setTimeout(loading, 0);
            this.props.notification({
                message: 'Di chuyển dữ liệu',
                description: 'Di chuyển dữ liệu không thành công ' + err.message,
                type: 'error'
            })
        }
    }
    getFileBackup = async () => {
        try {
            window.open('', 'TheWindow');
            document.getElementById('FormGetFileBackup').submit();
        } catch (err) {
            this.props.notification({
                message: 'Lấy file backup',
                description: err.message,
                type: 'error'
            })
        }
    }
    syncDataToMcare = async () => {
        try {
            if (this.state.listIdChecked.length == 0) {
                this.props.notification({
                    type: 'warning',
                    message: 'Đồng bộ dữ liệu sang us',
                    description: 'Bạn phải chọn ít nhất 1 đối tượng!'
                })
                return;
            }
            this.setState({ visibleSyncFbaseToMcare: true });
        } catch (err) {
            this.props.notification({
                message: 'Đồng bộ dữ liệu sang us',
                description: err.message,
                type: 'error'
            })
        }
    }
    dowloadFileBackup = async (user) => {
        try {
            let result = await fetchData({
                url: `api/v1/user_fb/file_backup/${user._id}`,
                method: 'post'
            });
            var blob = new Blob([result], { type: "text/plain;charset=utf-8" });
            FileSaver.saveAs(blob, `file-backup-${user.uid}.html`);
        } catch (err) {
            this.props.notification({
                message: 'Lấy file backup',
                description: err.message,
                type: 'error'
            })
        }
    }
    exportMultipleBackup = async () => {
        try {
            if (this.state.listIdChecked.length == 0) {
                this.props.notification({
                    type: 'warning',
                    message: 'Export file backup',
                    description: 'Bạn phải chọn ít nhất 1 đối tượng!'
                })
                return;
            }
            this.setState({ resultExportFile: { status: 'loading' } });
            let result = await fetchData({
                url: `api/v1/user_fb/backup/exports`,
                method: 'post',
                body: JSON.stringify({ ids: this.state.listIdChecked }),
                headers: { 'Content-Type': 'application/json' }
            });
            if (result.total > 0) {
                this.props.notification({
                    type: 'success',
                    message: 'Export file backup',
                    description: `Export thành công ${result.total} dữ liệu`
                })
                this.setState({
                    resultExportFile: {
                        link: `${process.env.REACT_APP_API}/${result.link}`
                    }
                })
            } else {
                this.props.notification({
                    type: 'warning',
                    message: 'Export file backup',
                    description: `Export không thành công, không có dữ liệu nào được export!`
                })
            }
        } catch (err) {
            this.props.notification({
                message: 'Lấy file backup',
                description: err.message,
                type: 'error'
            })
        }
    }
    moveDataSelected = () => {
        if (this.state.listIdChecked.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Di chuyển dữ liệu',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        // this.setState({ copyMenuRight: { list_ids: this.state.listIdChecked, action: 2 } })
        this.setState({
            visibleFormMoveDataToFolder: true
        })
    }
    moveDataAnotherUser = () => {
        if (this.state.listIdChecked.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Di chuyển dữ liệu',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        this.setState({
            visibleFormMoveToUser: true
        })
    }
    extLogin = () => {
        if (this.state.listIdChecked.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Login nick',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        this.setState({ visibleFormScriptLogin: true })
    }
    extJoinroup = () => {
        if (this.state.listIdChecked.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Join group',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        this.setState({ visibleFormCreateScript: { type: 'join_group' } })
    }
    extOpenCheckpoint = () => {
        if (this.state.listIdChecked.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Mở khóa nick',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        this.setState({ visibleFormCreateScript: { type: 'open_checkpoint' } })
    }
    extVerifyEmail = () => {
        if (this.state.listIdChecked.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Veriy email',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        this.setState({ visibleFormCreateScript: { type: 'verify_email' } })
    }
    getCookieExt = async (options = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                let params = {
                    folder_id: this.state.currentFolder || "",
                    type: this.state.typeScriptActionExt
                }

                if (this.state.queryGetCookie) params = { ...params, ...this.state.queryGetCookie };

                let result = await fetchData({
                    url: `api/v1/user_fb/new?${qs.stringify(params)}`,
                    method: 'get'
                });

                let option_data = options['data'] || {};

                let data = result.data;
                if (data) {
                    let send_data = {
                        login_cookie: option_data['login_cookie'],
                        script_action: {
                            ...option_data,
                            type: this.state.typeScriptActionExt
                        }
                    };
                    if (this.state.typeScriptActionExt == "addmail") {
                        if (!result.email) throw ({ message: 'Hết email' });
                        send_data['script_action']['email_add'] = {
                            email: result.email.email,
                            password: result.email.password
                        }
                    }

                    this.sendDataGetCookie({
                        nick_action: {
                            user_action: {
                                username: data.username,
                                uid: data.uid,
                                email: data.email,
                                password: data.password,
                                secret_2fa: data.secret_2fa,
                                cookie: data.cookie,
                                _id: data._id,
                            }
                        },
                        ...send_data
                    }, this.state.typeScriptActionExt);
                }
                resolve(data);
            } catch (err) {
                reject(err);
            }
        })
    }
    sendDataGetCookie = async (data, type) => {
        try {
            if (!window.extfbase || !window.extfbase.isConnected) {
                throw ({ message: 'Vui lòng kiểm tra tiện ích Fbase phiên bản mới nhất của chúng tôi!' })
            }
            console.log(data);
            let response = await window.extfbase.request({ method: 'action_ext', data: data, type_action: type });
        } catch (err) {
            let message = 'Đã có lỗi xảy ra!';
            if (typeof err == 'object') {
                message = err.message || message;
            }
            this.props.notification({
                type: 'warning',
                message: 'Trình get cookie',
                description: message
            })
        }
    }
    runGetCookie = async ({ max_tab, run, time_delay, max_nick_delay, max_nick_delay_time, max_nick_checkpoint, type, data, max_nick_checkpoint_type, query }) => {
        try {
            max_tab = max_tab ? max_tab : 1;
            time_delay = time_delay ? time_delay.split('-') : [1, 2];
            let min_timedelay = time_delay[0] ? parseInt(time_delay[0]) : 1;
            let max_timedelay = time_delay[1] ? parseInt(time_delay[1]) : 2;

            this.max_nick_checkpoint_type = max_nick_checkpoint_type;

            if (!this.state.run_get_cookie) {
                let dataRunGetCookie = this.state.dataRunGetCookie;
                if (!dataRunGetCookie) {
                    dataRunGetCookie = {
                        pending: 0,
                        checkpoint: 0,
                        die: 0,
                        success: 0,
                        run: run,
                        error: 0,
                        other: 0
                    }
                } else {
                    dataRunGetCookie['run'] = run;
                }
                dataRunGetCookie['total_run'] = 0;
                this.nick_run = 0;
                // console.log(max_nick_checkpoint);
                this.setState({
                    run_get_cookie: true,
                    dataRunGetCookie: dataRunGetCookie,
                    typeScriptActionExt: type,
                    queryGetCookie: query
                }, async () => {
                    try {
                        await this.saveLogGetCookie({ data: dataRunGetCookie, is_stop_checkpoint: 0 });
                        while (this.state.run_get_cookie) {
                            console.log('get run cookie -----> ', Date.now());
                            try {
                                console.log('max_nick_checkpoint ----> ', max_nick_checkpoint, 'nick_checkpoint', this.nick_checkpoint);

                                if (max_nick_checkpoint && this.nick_checkpoint == max_nick_checkpoint) {
                                    this.setState({
                                        run_get_cookie: false,
                                        visibleStop: "Tiến trình tạm dừng vì đạt giới hạn số lượng checkpoint."
                                    }, async () => {
                                        this.nick_checkpoint = 0;
                                        await this.saveLogGetCookie({ is_stop_checkpoint: 1 });
                                    })
                                    break;
                                }
                                if (dataRunGetCookie.pending < max_tab && dataRunGetCookie) {
                                    let time_radom = (Math.floor(Math.random() * max_timedelay) + min_timedelay);
                                    // console.log(time_radom);
                                    if (dataRunGetCookie.total_run > 1) await this.sleep(time_radom * 1000);
                                    if (max_nick_delay && this.nick_run >= max_nick_delay) {
                                        await this.sleep(max_nick_delay_time * 1000);
                                        this.nick_run = 0;
                                    }
                                    if (!this.state.run_get_cookie) break;
                                    let doc = await this.getCookieExt({ data });
                                    if (!doc) {
                                        // if(type == "get_cookie"){
                                        //     this.setState({
                                        //         run_get_cookie: false,
                                        //         visibleStop: false
                                        //     }, async () => {
                                        //         this.nick_checkpoint = 0;
                                        //         await this.saveLogGetCookie({ data: dataRunGetCookie });
                                        //     })
                                        //     break;
                                        // }
                                    } else {
                                        dataRunGetCookie.pending += 1;
                                        dataRunGetCookie.total_run += 1;
                                        this.nick_run++;
                                        this.setState({
                                            visibleStop: false
                                        })
                                    }
                                }
                            } catch (err) {
                                console.log(err);
                                if (err.message.indexOf("email") > -1) {
                                    this.setState({
                                        visibleStop: err.message
                                    })
                                }
                            }
                            await this.sleep(3000);
                            await this.saveLogGetCookie({ data: dataRunGetCookie });
                        }
                    } catch (err) {
                        console.log(err);
                        this.setState({
                            run_get_cookie: false
                        })
                        this.props.notification({
                            type: 'warning',
                            message: 'Tiến trình',
                            description: err.message || 'Đã có lỗi xảy ra!'
                        })
                    }
                })
            }
        } catch (err) {
            console.log(err);
            this.setState({
                run_get_cookie: false
            }, async () => {
                this.nick_checkpoint = 0;
            })
            this.props.notification({
                type: 'warning',
                message: 'Tiến trình',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    }
    stopGetCookie = () => {
        this.setState({
            run_get_cookie: false
        })
    }
    sleep = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(ms);
            }, ms)
        });
    }
    saveLogGetCookie = async (options = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                let params = {
                    count: 1,
                    folder_id: this.state.currentFolder || "",
                    type: this.state.typeScriptActionExt
                }

                if (this.state.queryGetCookie) params = { ...params, ...this.state.queryGetCookie };

                let count_run = await this.props.fetchData({
                    url: `api/v1/user_fb/new?${qs.stringify(params)}`,
                    method: 'get'
                });
                if (options['data']) {
                    options['data']['run'] = count_run.data;
                }
                let result = await fetchData({
                    url: `api/v2/app/log_get_cookie`,
                    method: 'post',
                    body: JSON.stringify({
                        profile_id: window.extfbase._profile_id,
                        ...options
                    }),
                    headers: { 'Content-Type': 'application/json' }
                });

                let dataRunGetCookie = this.state.dataRunGetCookie || {};
                dataRunGetCookie['run'] = count_run.data;
                this.setState({
                    dataRunGetCookie: dataRunGetCookie
                })
                resolve(result);
            } catch (err) {
                reject(err);
            }
        })
    }
    visibleFormScript = (option) => {
        try {
            let { state, title } = option;

            if (this.state.listIdChecked.length == 0) {
                this.props.notification({
                    type: 'warning',
                    message: title,
                    description: 'Bạn phải chọn ít nhất 1 đối tượng!'
                })
                return;
            }
            this.setState(state);
        } catch (err) {

        }
    }
    updateMany = async (values) => {
        var { id, type, list_ids, data } = values;
        data = data ? data : {};

        if (type == 1) {
            list_ids = [id];
        } else {
            list_ids = this.state.listIdChecked;
        }
        if (list_ids.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Cập nhập dữ liệu',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Cập nhập ${list_ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/user_fb/update_many',
                            method: 'post',
                            body: JSON.stringify({ ids: list_ids.join(','), ...data }),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        notification({
                            type: 'success',
                            message: 'Cập nhập dữ liệu',
                            description: `Bạn đã cập nhập thành công`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Cập nhập dữ liệu',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    openFormAddMail = () => {
        this.setState({ visibleScriptAddMail: true });
    }
    showCheckLiveInsta = () => {
        let list_nicks = this.state.listData.filter((item) => this.state.listIdChecked.indexOf(item._id) > -1).map((item) => {
            return {
                uid: item.uid,
                _id: item._id
            }
        });
        this.setState({
            visibleCheckLiveInsta: true,
            listNickCheckLiveInsta: list_nicks
        })
    }
    showFormCreateAction = (platform, ids = []) => {
        if (!ids.length) {
            this.props.notification({
                type: 'error',
                message: 'Tạo chiến dịch',
                description: "Chọn ít nhất 1 tài nguyên để thực hiện hành động này!"
            })
        } else {
            let data = this.state.listData.filter((item) => ids.includes(item._id));
            let device_ids = [...new Set(data.map((item) => item.device_id))];

            this.setState({
                visibleCreateAction: {
                    visible: true,
                    platform: platform,
                    list_uids: ids,
                    device: {
                        device_ids
                    }
                }
            })
        }
    }

    selectDataSpeed = (values) => {
        try{
            let data = [...this.state.listData];

            if (values.type == 2) {
                let device_ids = [...new Set(data.map((item) => item.device_id))].map((device_id) => {
                    return {
                        device_id: device_id,
                        nicks: data.filter((_item) => _item.device_id === device_id)
                    }
                }).sort((a, b) => a.nicks.length - b.nicks.length);

                let data_selected = [], index_device = 0;

                for (let i = 0; i < values.num; i++) {
                    device_ids = device_ids.filter((item) => item.nicks.length > 0);

                    if (device_ids.length) {
                        if (index_device >= device_ids.length) index_device = 0;
                        data_selected = data_selected.concat(device_ids[index_device].nicks.splice(0, 1));
                        index_device++;
                    } else {
                        break;
                    }
                }
                data = data_selected;
            } else {
                data = data.slice(0, values['num'])
            }


            this.setState({
                visibleSelectOpt: false,
                listIdChecked: data.map((item) => item._id),
                listUidChecked: data.map((item) => item.uid)
            })
        } catch(err){
            console.log(err);
            this.props.notification({
                type: 'error',
                message: 'Chọn nhanh',
                description: "Đã xảy ra lỗi!"
            })
        }
    }

    render() {
        var menu_context = null;
        var { dataMenuRight, copyMenuRight } = this.state;
        if (dataMenuRight) {
            if (dataMenuRight.type == 1) {
                let copy_menu = null, menu_folder = null;
                if (copyMenuRight) {
                    copy_menu = <li key="1" onClick={() => this.moveData({ ...copyMenuRight, to_folder_id: dataMenuRight.folder.folder_id })}>Paste data from "{copyMenuRight.folder_name}"</li>;
                }
                if (dataMenuRight.folder && dataMenuRight.folder.folder_id) {
                    menu_folder = [<li key="0" onClick={() => this.setState({ copyMenuRight: { action: 1, folder_id: dataMenuRight.folder.folder_id, folder_name: dataMenuRight.folder.folder_name } })}>Copy data</li>,
                        copy_menu, <li key="3" onClick={() => {
                            this.props.copyText(dataMenuRight.folder.folder_id, 'Copy folder id')
                        }}>Copy folder id</li>,
                    <li key="2" onClick={() => this.removeFolder(dataMenuRight.folder.folder_id)}>Delete folder</li>
                    ]
                }
                menu_context = <ul>
                    {dataMenuRight.folder && dataMenuRight.folder.folder_id ? <li className="title">{dataMenuRight.folder.folder_name}</li> : null}
                    <li onClick={() => this.saveFolder({
                        type: 'create',
                        folder_id: dataMenuRight.folder ? dataMenuRight.folder.folder_id : 0
                    })}>Add folder</li>

                    {dataMenuRight.folder && dataMenuRight.folder.folder_id ? <li onClick={() => this.saveFolder({
                        type: 'update',
                        folder_id: dataMenuRight.folder.folder_id,
                        folder_name: dataMenuRight.folder.folder_name
                    })}>Edit name folder</li> : null}

                    {dataMenuRight.folder && dataMenuRight.folder.folder_id ? <li onClick={() => this.setState({ visibleFormMoveData: true })}>Move folder to another user</li> : null}
                    {menu_folder}
                </ul>
            } else {
                menu_context = <ul className="menu" data-id={dataMenuRight.item._id}>
                    <li className="title">{dataMenuRight.item.uid}</li>
                    {/* <li onClick={() => this.setState({ copyMenuRight: { list_ids: [dataMenuRight.item._id], action: 2 } })}>Move</li> */}
                    <li onClick={this.moveDataSelected}>Move data selected</li>
                    <li onClick={() => this.removeMany({ type: 2, list_ids: this.state.listIdChecked })}>Delete selected</li>
                    <li onClick={() => this.removeMany({ type: 1, id: dataMenuRight.item._id })}>Delete</li>
                    <li onClick={this.moveDataAnotherUser}>Move data to another user</li>
                    <li className="border-top" onClick={() => this.getFileBackup(dataMenuRight.item._id)}>View file Backup</li>
                    <li onClick={() => this.dowloadFileBackup(dataMenuRight.item)}>Dowload File Backup</li>
                    <li onClick={this.exportMultipleBackup}>Export file zip backup</li>
                    <li className="border-top">
                        <span>Tính năng</span>
                        <i className="far fa-chevron-right"></i>
                        <ul className="ul-children">
                            {
                                this.props.userSystem.level > 2 ?
                                    <li className="border-top">
                                        <span>Us</span>
                                        <i className="far fa-chevron-right"></i>
                                        <ul className="ul-children">
                                            <li onClick={() => this.setState({ visibleAddUs: true })}>
                                                {'Đẩy nick & email'}
                                            </li>
                                        </ul>
                                    </li>
                                    : null
                            }
                            <li>
                                <span>Facebook</span>
                                <i className="far fa-chevron-right"></i>
                                <ul className="ul-children">
                                    <li onClick={this.extLogin}>Login</li>
                                    <li onClick={this.extJoinroup}>Join group</li>
                                    <li onClick={this.extOpenCheckpoint}>Open checkpoint</li>
                                    {this.props.userSystem.level > 2 ? <li onClick={() => this.setState({ visibleFormGetCookie: true })}>Get cookie</li> : null}
                                </ul>
                            </li>
                            {
                                this.props.userSystem.level > 2 ?
                                    <li>
                                        <span>Email</span>
                                        <i className="far fa-chevron-right"></i>
                                        <ul className="ul-children">
                                            <li onClick={this.openFormAddMail}>Add mail</li>
                                            {this.props.userSystem.level >= 5 ? <li onClick={this.extVerifyEmail}>Verify email</li> : null}
                                            <li className="border-top" onClick={() => this.updateMany({ data: { status_add_mail: 0, count_add_email: 0, status: 1 } })}>Reset add mail</li>
                                        </ul>
                                    </li> : null
                            }
                            {
                                this.props.userSystem.level > 2 ?
                                    <li >
                                        <span>Ads</span>
                                        <i className="far fa-chevron-right"></i>
                                        <ul className="ul-children">
                                            <li className="border-top" onClick={() => this.visibleFormScript({
                                                state: {
                                                    visibleCheckAds: true
                                                },
                                                title: 'Kiểm tra loại xác minh danh tính'
                                            })}>Check acc ads</li>
                                            <li onClick={() => this.visibleFormScript({
                                                state: {
                                                    visibleCheckPriceAds: true
                                                },
                                                title: 'Kiểm tra ngưỡng ads'
                                            })}>Check ngưỡng ads</li>
                                        </ul>
                                    </li> : null
                            }
                            <li>
                                <span>Instagram</span>
                                <i className="far fa-chevron-right"></i>
                                <ul className="ul-children">
                                    <li onClick={this.showCheckLiveInsta}>Check live</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>Tạo hành động</span>
                        <i className="far fa-chevron-right"></i>
                        <ul className="ul-children">
                            {
                                this.props.Access && this.props.Access.Platform.data.map((item) => {
                                    return <li onClick={() => {
                                        this.showFormCreateAction(item, this.state.listIdChecked)
                                    }}>
                                        {item}
                                    </li>
                                })
                            }
                        </ul>
                    </li>
                    <li className="border-top" onClick={() => this.updateMany({ data: { status: 0, last_time_check: 0 } })}>Reset status</li>
                    <li onClick={() => {
                        this.setState({
                            visibleResetDeviceId: true
                        })
                    }}>Reset DeviceId</li>
                </ul>
            }
        }

        return (
            <React.Fragment>
                <ModalUploadUserFb {...this.props} visible={this.state.visibleModalUpload} hide={() => this.setState({ visibleModalUpload: false })}
                    success={this.uploadUserFbDone} currentFolder={this.state.currentFolder}
                />
                <ModalExportUserFb {...this.props} visible={this.state.visibleModalExport} hide={() => this.setState({ visibleModalExport: false })}
                    listData={this.state.listData} listIdChecked={this.state.listIdChecked}
                />
                <FormSaveFolder {...this.props} visible={this.state.visibleModalFormFolder} hide={() => this.setState({ visibleModalFormFolder: null })}
                    // currentFolder={this.state.folderIdAddChild}
                    getListData={this.getListData} getListFolder={this.getListFolder}
                />
                <PopupMenu {...this.state.popup} menu={menu_context} />

                <FormCreateScript {...this.props} visible={this.state.visibleFormCreateScript ? true : false} hide={() => this.setState({ visibleFormCreateScript: null })} listIdChecked={this.state.listIdChecked} option={this.state.visibleFormCreateScript} />

                <FormCheckUid {...this.props} visible={this.state.visibleFormCheckUid} hide={() => this.setState({ visibleFormCheckUid: false })} listUidChecked={this.state.listUidChecked} resetData={() => this.getListData()} />

                <SyncMcare {...this.props} currentFolder={this.state.currentFolder} visible={this.state.visibleSyncMcare} hide={() => this.setState({ visibleSyncMcare: false })} resetData={() => this.getListData()} />

                <SyncFbaseToMcare {...this.props} currentFolder={this.state.currentFolder} visible={this.state.visibleSyncFbaseToMcare} hide={() => this.setState({ visibleSyncFbaseToMcare: false })}
                    resetData={() => this.getListData()}
                    listIdChecked={this.state.listIdChecked}
                    listData={this.state.listData}
                />

                <FormScriptLogin {...this.props} visible={this.state.visibleFormScriptLogin} hide={() => this.setState({ visibleFormScriptLogin: false })} listIdChecked={this.state.listIdChecked} />

                <FormMoveDataToFolder {...this.props} currentFolder={this.state.currentFolder} visible={this.state.visibleFormMoveDataToFolder} hide={() => this.setState({ visibleFormMoveDataToFolder: false })}
                    resetData={() => this.getListData()}
                    listIdChecked={this.state.listIdChecked}
                    listFolder={this.state.folderDataParse}
                />

                <FormMoveToUser {...this.props} visible={this.state.visibleFormMoveToUser} hide={() => this.setState({ visibleFormMoveToUser: false })} resetData={this.getListData} listIdChecked={this.state.listIdChecked} />

                <ResultExportFileBackup {...this.props} visible={this.state.resultExportFile ? true : false} hide={() => this.setState({ resultExportFile: null })} link={this.state.resultExportFile ? this.state.resultExportFile.link : ""} />

                {
                    (dataMenuRight && dataMenuRight.folder) || (this.state.visibleFormMoveData == 2) ?
                        <FormMoveData folder={this.state.visibleFormMoveData == 2 ? this.state.folderData.find((item) => item.folder_id == this.state.currentFolder) : dataMenuRight.folder} {...this.props} visible={this.state.visibleFormMoveData} hide={() => this.setState({ visibleFormMoveData: false })} resetData={() => {
                            this.getListFolder();
                            this.getListData({ type: 'reset' });
                        }} /> : null
                }

                <FormGetCookie {...this.props} visible={this.state.visibleFormGetCookie} hide={() => this.setState({ visibleFormGetCookie: false, dataRunGetCookie: null, run_get_cookie: false })}
                    currentFolder={this.state.currentFolder} runGetCookie={this.runGetCookie} stopGetCookie={this.stopGetCookie}
                    run={this.state.run_get_cookie} dataRunGetCookie={this.state.dataRunGetCookie}
                    listFolder={this.state.folderDataParse}
                />
                <AlertStop visible={this.state.visibleStop} hide={() => this.setState({ visibleStop: false })} title={this.state.visibleStop} />

                <ScriptCheckAds {...this.props} visible={this.state.visibleCheckAds} hide={() => this.setState({ visibleCheckAds: false })} listIdChecked={this.state.listIdChecked}
                    listData={this.state.listData}
                />
                <CheckPriceAds {...this.props} visible={this.state.visibleCheckPriceAds} hide={() => this.setState({ visibleCheckPriceAds: false })} listIdChecked={this.state.listIdChecked}
                    listData={this.state.listData}
                />
                <InfoUserFb visible={this.state.detailUser} user={this.state.detailUser || {}} hide={() => this.setState({ detailUser: null })} />

                <FormScriptAddMail {...this.props} visible={this.state.visibleScriptAddMail}
                    hide={() => this.setState({ visibleScriptAddMail: false, dataRunGetCookie: null, run_get_cookie: false })}
                    currentFolder={this.state.currentFolder}
                    runGetCookie={this.runGetCookie} stopGetCookie={this.stopGetCookie}
                    run={this.state.run_get_cookie} dataRunGetCookie={this.state.dataRunGetCookie}
                />

                <FormAddUs {...this.props} visible={this.state.visibleAddUs}
                    hide={() => this.setState({ visibleAddUs: false })}
                    currentFolder={this.state.currentFolder}
                />

                <CheckLiveInstagramComponent
                    visible={this.state.visibleCheckLiveInsta}
                    onCancel={() => this.setState({
                        visibleCheckLiveInsta: false
                    }, () => {
                        this.getListData();
                    })}
                    nicks={this.state.listNickCheckLiveInsta || []}
                />

                <ChangeDeviceIdComponent
                    visible={this.state.visibleResetDeviceId}
                    onCancel={() => this.setState({
                        visibleResetDeviceId: false
                    }, () => {
                        this.getListData();
                    })}
                    _id={this.state.listIdChecked || []}
                />

                <HomeCreateActionWidget
                    {
                    ...this.state.visibleCreateAction
                    }
                    hide={() => this.setState({
                        visibleCreateAction: {
                            visible: false
                        }
                    })}
                />

                <WidgetLogAction 
                    {...this.state.visibleLogAction}
                    onClose={() => this.setState({ visibleLogAction: { visible: false }})}
                />

                <form id="FormGetFileBackup" method="post" action={`${process.env.REACT_APP_API}/api/v1/user_fb/file_backup/${dataMenuRight && dataMenuRight.item ? dataMenuRight.item._id : ''}`} target="TheWindow">
                    <input type="hidden" name="jwt" value={AccountMetaData.getToken()} />
                </form>

                <div className="row">
                    <div className="col-md-2">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <strong>Quản lý nhóm</strong>
                                    </div>
                                </div>
                                <div className="btn-folder-box mb-10">
                                    <div className="mr-10">
                                        <a className="btn btn-sm btn-circle btn-light border-0" onClick={() => this.saveFolder({
                                            type: 'create',
                                            folder_id: this.state.currentFolder
                                        })}>
                                            <img src={require('../media/images/add-folder.png')} width="25" />
                                        </a>
                                    </div>
                                    <div>
                                        <a className="btn btn-sm btn-circle btn-light border-0" onClick={() => this.removeFolder(parseInt(this.state.currentFolder))}>
                                            <img src={require('../media/images/del-folder.png')} width="25" />
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 menu-folder p-0">
                                        {/* <Menu
                                                    mode="inline"
                                                    openKeys={this.state.openKeys}
                                                    onOpenChange={this.handleChangeFolder}
                                                    selectedKeys={[this.state.currentFolder]}
                                                    onClick={this.handleClickFolder}
                                                >
                                                    <Menu.Item key="0">
                                                        <span className="head-menu" onContextMenu={(e) => this.handleContextMenu(e)}>
                                                            <span>Tất cả</span>
                                                        </span>
                                                    </Menu.Item>
                                                    {
                                                        this.state.folderDataParse.map((menu) => {
                                                            return this.renderMenu(menu);
                                                        })
                                                    }
                                                </Menu> */}
                                        <DirectoryTree
                                            multiple
                                            onSelect={(key, info) => {
                                                this.setState({ currentFolder: key[0] }, () => this.getListData('reset'));
                                            }}
                                            treeData={this.state.folderDataParse}
                                            onRightClick={({ event, node }) => this.handleContextMenu(event, node)}
                                            selectedKeys={[this.state.currentFolder]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-10">
                                    <div className="col-md-9 mb-20 mb-md-0">
                                        <div className="tool-btn-action">
                                            <button className="btn btn-info btn-sm mr-2">Tất cả ({this.state.totalData ? this.state.totalData : 0})</button>
                                            <Tooltip title="Xóa" placement="bottom">
                                                <button className="btn btn-danger btn-sm mr-2 btn-remove-nc" onClick={() => this.removeMany({ type: 2, list_ids: this.state.listIdChecked })}>
                                                    <i className="fa fa-trash-o"></i>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Export" placement="bottom">
                                                <button className="btn btn-primary btn-sm mr-2" onClick={() => this.setState({ visibleModalExport: true })}>
                                                    <i className="fa fa-download"></i>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Import" placement="bottom">
                                                <button className="btn btn-primary btn-sm mr-2" onClick={() => this.setState({ visibleModalUpload: true })}>
                                                    <i className="fa fa-upload"></i>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Check sống/chết" placement="bottom">
                                                <button className="btn btn-primary btn-sm mr-2" onClick={() => {
                                                    if (this.state.listUidChecked.length > 0) {
                                                        this.setState({ visibleFormCheckUid: true })
                                                    } else {
                                                        this.props.notification({
                                                            type: 'warning',
                                                            message: 'Lỗi check uid',
                                                            description: 'Bạn phải chọn ít nhất 1 đối tượng!'
                                                        })
                                                    }
                                                }}>
                                                    <i className="fa fa-check"></i>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Đồng bộ dữ liệu từ us" placement="bottom">
                                                <button className="btn btn-primary btn-sm mr-2" onClick={() => this.setState({ visibleSyncMcare: true })}>
                                                    <i className="fa fa-share-alt"></i>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title="Đồng bộ dữ liệu sang us" placement="bottom">
                                                <button className="btn btn-primary btn-sm mr-2" onClick={this.syncDataToMcare}>
                                                    <i className="si si-loop"></i>
                                                </button>
                                            </Tooltip>
                                            <button id="btn-more-tool" className="btn btn-primary btn-sm mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="si si-plus"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right min-width-200"
                                                aria-labelledby="btn-more-tool">
                                                {/* <h5 className="h6 text-center py-10 mb-5 border-b text-uppercase">Tài khoản</h5> */}
                                                <a className="dropdown-item" onClick={this.moveDataSelected}>
                                                    Move data selected
                                                </a>
                                                <a className="dropdown-item border-top" onClick={this.moveDataAnotherUser}>
                                                    Move data to another user
                                                </a>
                                                {
                                                    this.state.currentFolder ?
                                                        <a className="dropdown-item" onClick={() => {
                                                            this.setState({
                                                                visibleFormMoveData: 2
                                                            })
                                                        }}>
                                                            Move folder to another user
                                                        </a> : null
                                                }
                                                <a className="dropdown-item border-top" onClick={this.exportMultipleBackup}>
                                                    Export file zip backup
                                                </a>
                                                <a className="dropdown-item border-top" onClick={this.extLogin}>
                                                    Login
                                                </a>
                                                <a className="dropdown-item" onClick={this.extJoinroup}>
                                                    Join group
                                                </a>
                                                <a className="dropdown-item" onClick={this.extOpenCheckpoint}>
                                                    Open checkpoint
                                                </a>
                                                {
                                                    this.props.userSystem.level > 2 ? <a className="dropdown-item border-top" onClick={() => this.setState({ visibleFormGetCookie: true })}>
                                                        Get cookie
                                                    </a> : null
                                                }
                                                <a className="dropdown-item" onClick={() => this.updateMany({ data: { status: 0, last_time_check: 0 } })}>Reset status</a>
                                                <a className="dropdown-item" onClick={() => {
                                                    this.setState({
                                                        visibleResetDeviceId: true
                                                    })
                                                }}>Reset DeviceId</a>
                                            </div>
                                            <Tooltip title="Làm mới" placement="bottom">
                                                <button className="btn btn-primary btn-sm mr-2" onClick={this.getListData}>
                                                    <i className="si si-reload"></i>
                                                </button>
                                            </Tooltip>
                                            <Popover
                                                content={<div>
                                                    {
                                                        this.props.Access && this.props.Access.Platform.data.map((item) => {
                                                            return <p className='mb-2'>
                                                                <a href="#" onClick={() => {
                                                                    this.showFormCreateAction(item, this.state.listIdChecked)
                                                                }}>
                                                                    {item}
                                                                </a>
                                                            </p>
                                                        })
                                                    }
                                                </div>}
                                                zIndex={999}
                                            >
                                                <button className="btn btn-primary btn-sm mr-2">
                                                    Tạo hành động
                                                </button>
                                            </Popover>

                                            <Tooltip title="Chọn nhanh" placement="bottom">
                                                <Popover
                                                    content={
                                                        <div>
                                                            <Form
                                                                onFinish={this.selectDataSpeed}
                                                                initialValues={{
                                                                    num: 5,
                                                                    type: 1
                                                                }}
                                                                layout="vertical"
                                                            >
                                                                <Form.Item
                                                                    label="Nhập số tài khoản cần chọn"
                                                                    name="num"
                                                                    rules={[{ required: true, message: 'Bạn phải nhập số tài khoản!' }]}
                                                                >
                                                                    <InputNumber />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label="Kiểu chọn"
                                                                    name="type"
                                                                >
                                                                    <Radio.Group>
                                                                        <Radio value={1}>Ngẫu nhiên</Radio>
                                                                        <Radio value={2}>Theo device id</Radio>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                                <Button type="primary" htmlType="submit">Chọn</Button>
                                                            </Form>
                                                        </div>
                                                    }
                                                    title="Chọn nhanh"
                                                    trigger="click"
                                                    visible={this.state.visibleSelectOpt}
                                                    onVisibleChange={(visible) => this.setState({ visibleSelectOpt: visible })}
                                                >
                                                    <button className="btn btn-primary btn-sm mr-2" onClick={() => this.setState({ visibleSelectOpt: true })}>
                                                        Chọn nhanh
                                                    </button>
                                                </Popover>
                                            </Tooltip>
                                        </div>
                                        {/* <button className="btn btn-primary btn-sm mr-2 btn-filter" data-toggle="tooltip" title="Lọc">
                                                    <i className="fa fa-filter"></i>
                                                </button> */}
                                    </div>
                                    <div className="col-md-3">
                                        <Input placeholder="Tìm kiếm uid: 122344,23443" onChange={this.handleFilterSearch} name="uids"
                                            value={this.state.valuesFilter['uids'] ? this.state.valuesFilter['uids'] : ''} />
                                    </div>
                                </div>
                                <Form
                                    initialValues={this.state.initialValues}
                                    onChange={(e) => {
                                        // if (e.target.nodeName == 'INPUT') {
                                        //     if (this.timeout) clearTimeout(this.timeout);
                                        //     this.timeout = setTimeout(() => {
                                        //         this.getListData('reset');
                                        //     }, 300)
                                        // } else {
                                        //     this.getListData('reset');
                                        // }
                                        if (this.timeout) clearTimeout(this.timeout);
                                            this.timeout = setTimeout(() => {
                                                this.getListData('reset');
                                            }, 300)
                                    }}
                                    ref={(evt) => this.formFilter = evt}
                                >
                                    <Form.Item name="min_friend_count" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item name="max_friend_count" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <div className="row tool-filter">
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="log">
                                                <Input placeholder="Log.." />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="status">
                                                <Select style={{ width: "100%" }}
                                                    onChange={() => this.getListData('reset')}
                                                >
                                                    <Option value="">Trạng thái</Option>
                                                    <Option value={1}>Hoạt động</Option>
                                                    <Option value={2}>Lỗi đăng nhập</Option>
                                                    <Option value={3}>Checkpoint</Option>
                                                    <Option value={4}>Die</Option>
                                                    {
                                                        this.props.userSystem.level > 2 ?
                                                            <Option value={5}>Đang check</Option> : null
                                                    }
                                                    <Option value={0}>Chưa check</Option>
                                                    <Option value={6}>Không xác định</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-lg-2 col-md-3">
                                            <Form.Item name="email_status">
                                                <Select
                                                    style={{ width: "100%" }}
                                                    onChange={() => this.getListData('reset')}
                                                >
                                                    <Option value="">Trạng thái email</Option>
                                                    <Option value={1}>Email Hoạt động</Option>
                                                    <Option value={2}>Email không hoạt động</Option>
                                                    <Option value={0}>Email chưa check</Option>
                                                    <Option value={"password is incorrect"}>Sai password</Option>
                                                    <Option value={"Login email thành công"}>Login thành công</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        {
                                            this.props.userSystem.level > 2 ?
                                                <div className="col-lg-2 col-md-3">
                                                    <Form.Item name="status_ads">
                                                        <Select
                                                            style={{ width: "100%" }}
                                                            onChange={() => this.getListData('reset')}
                                                        >
                                                            <Option value="">Trạng thái ads</Option>
                                                            <Option value={0}>Chưa kiểm tra</Option>
                                                            <Option value={1}>Hoạt động</Option>
                                                            <Option value={2}>Xác minh danh tính</Option>
                                                            <Option value={4}>Không xác định</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                : null
                                        }
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="is_cookie">
                                                <Select style={{ width: "100%" }}
                                                    onChange={() => this.getListData('reset')}
                                                >
                                                    <Option value="">Cookie</Option>
                                                    <Option value={2}>Có cookie</Option>
                                                    <Option value={1}>Không có cookie</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="status_add_mail">
                                                <Select style={{ width: "100%" }}
                                                    onChange={() => this.getListData('reset')}
                                                >
                                                    <Option value="">TT add mail</Option>
                                                    <Option value={0}>Chưa add</Option>
                                                    <Option value={2}>Thành công</Option>
                                                    <Option value={1}>Đang chạy</Option>
                                                    <Option value={3}>Lỗi</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Form.Item name="device_group" className="col-md-3 col-lg-2">
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Box name"
                                                options={this.state.listDeviceGroup.map((item) => {
                                                    return {
                                                        value: item,
                                                        label: item
                                                    }
                                                })}
                                                showArrow
                                                showSearch
                                                allowClear
                                                mode="multiple"
                                                onChange={() => this.getListData('reset')}
                                                maxTagCount={1}
                                            />
                                        </Form.Item>
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="device_ids">
                                                <Select mode="tags" placeholder="Lọc theo thiết bị"
                                                    maxTagCount={1}
                                                    allowClear
                                                    onChange={() => this.getListData('reset')}
                                                >
                                                    <Select.Option value="is_online">Đang online</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="task_count">
                                                <InputNumber addonBefore="Hành động <=" min={0}/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="proxy">
                                                <Input placeholder="Proxy" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="status_sync_us">
                                                <Select style={{ width: "100%" }}
                                                    onChange={() => this.getListData('reset')}
                                                >
                                                    <Option value="">TT sync us</Option>
                                                    <Option value={2}>Đã sync</Option>
                                                    <Option value={3}>Lỗi sync</Option>
                                                    <Option value={0}>Chưa sync</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3 col-lg-2">
                                            <Form.Item name="is_device">
                                                <Select style={{ width: "100%" }}
                                                    onChange={() => this.getListData('reset')}
                                                    placeholder="Lọc device"
                                                >
                                                    <Option value={1}>Không có device</Option>
                                                    <Option value={2}>Có device</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Item name="start_time" noStyle><Input type="hidden" /></Form.Item>
                                            <Form.Item name="end_time" noStyle><Input type="hidden" /></Form.Item>
                                            <Form.Item >
                                                <DatePicker.RangePicker
                                                    onChange={(values => {
                                                        this.formFilter.setFieldsValue({
                                                            start_time: values ? values[0].startOf("day").unix() * 1000 : null,
                                                            end_time: values ? values[1].endOf("day").unix() * 1000 : null
                                                        })
                                                        this.getListData('reset');
                                                    })}
                                                    locale={locale}
                                                    className="w-100"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="col-auto">
                                            <Tooltip title="Lọc bạn bè" placement="bottom">
                                                <Popover
                                                    content={
                                                        <div>
                                                            <Form
                                                                ref={(evt) => this.formFilterFriend = evt}
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                                layout="vertical"
                                                            >
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <Form.Item
                                                                            label="Min"
                                                                            name="min_friend_count"
                                                                        >
                                                                            <InputNumber min={0} />
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Form.Item
                                                                            label="Max"
                                                                            name="max_friend_count"
                                                                        >
                                                                            <InputNumber min={0} />
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                                <Button type="primary" onClick={() => {
                                                                    this.formFilter.setFieldsValue({
                                                                        ...this.formFilterFriend.getFieldsValue()
                                                                    })
                                                                    this.getListData('reset');
                                                                }}>Chọn</Button>
                                                            </Form>
                                                        </div>
                                                    }
                                                    title="Lọc bạn bạn bè"
                                                    trigger="click"
                                                    visible={this.state.visibleFilterFriends}
                                                    onVisibleChange={(visible) => this.setState({ visibleFilterFriends: visible })}
                                                    placement={"bottomLeft"}
                                                >
                                                    <Button style={{ width: "100%" }} type="primary" size={"middle"} onClick={() => this.setState({ visibleFilterFriends: true })}>
                                                        <i className="far fa-user-friends"></i>
                                                    </Button>
                                                </Popover>
                                            </Tooltip>
                                        </div>
                                        <div className="col-auto">
                                            <label className="btn btn-primary btn-middle m-0 text-center">
                                                <UploadOutlined className="mr-5" /> Lọc uid: {this.state.valuesFilter['uids_file'] ? this.state.valuesFilter['uids_file'].length : 0}
                                                <Input key={this.state.keyFileFilter || Date.now()} type="file" accept=".txt" style={{ display: "none" }} onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        var reader = new FileReader();
                                                        var _this = this;
                                                        reader.onload = function (e) {
                                                            let uids = reader.result.split('\n');
                                                            let valuesFilter = _this.state.valuesFilter;
                                                            valuesFilter['uids_file'] = uids.join(',').replace(/\r/gi, '').split(',');
                                                            _this.setState({ valuesFilter, keyFileFilter: Date.now() }, () => _this.getListData('reset'));
                                                        }
                                                        reader.readAsText(e.target.files[0]);
                                                    }
                                                }} />
                                            </label>
                                        </div>
                                        <div className="col-auto">
                                            <Button
                                                icon={<DeleteOutlined />}
                                                type="primary"
                                                size={"middle"}
                                                onClick={() => this.setState({ valuesFilter: {} }, () => {
                                                    this.formFilter.resetFields();
                                                    this.formFilter.setFieldsValue({
                                                        device_id: undefined
                                                    })
                                                    this.getListData('reset');
                                                })}
                                            >
                                                Xóa lọc
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <div className="d-flex align-items-center mb-20">
                                    <Pagination
                                        total={this.state.totalData}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200, 500, 1000, 2000, 3000]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            // this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                    <div className="ml-10 d-sm-none">
                                        <Select onChange={(e) => {
                                            this.limit = e;
                                            this.getListData();
                                        }}>
                                            <Select.Option value={20}>20 / page</Select.Option>
                                            <Select.Option value={50}>50 / page</Select.Option>
                                            <Select.Option value={100}>100 / page</Select.Option>
                                            <Select.Option value={200}>200 / page</Select.Option>
                                            <Select.Option value={500}>500 / page</Select.Option>
                                            <Select.Option value={1000}>1000 / page</Select.Option>
                                            <Select.Option value={2000}>2000 / page</Select.Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <WidgetTable
                                            dataSource={this.state.listData}
                                            loading={this.state.loading}
                                            pagination={false}
                                            rowKey={"_id"}
                                            rowSelection={{
                                                onChange: (selectedKeys) => {
                                                    this.setState({
                                                        listIdChecked: selectedKeys,
                                                        listUidChecked: this.state.listData.filter((item) => selectedKeys.indexOf(item._id) > -1).map((item) => item.uid)
                                                    })
                                                },
                                                selectedRowKeys: this.state.listIdChecked,
                                                fixed: "left"
                                            }}
                                            scroll={{ x: 1200, y: 800 }}
                                            onRow={(record) => {
                                                return {
                                                    onContextMenu: e => this.handleContextMenuTable(e, record)
                                                }
                                            }}
                                            columns={[
                                                {
                                                    title: `(${this.state.listIdChecked.length}) Uid`,
                                                    dataIndex: "uid",
                                                    width: 180,
                                                    render: (value, record) => {
                                                        return <div style={{ width: "180px" }}>
                                                            <div className="d-flex" style={{ alignItems: "center" }}>
                                                                <span>{record.uid || record.username}</span>
                                                            </div>
                                                            {
                                                                record.full_name && <div>{record.full_name}</div>
                                                            }
                                                            <div className="mt-5 d-flex align-items-center">
                                                                {
                                                                    record.status == 1 ?
                                                                        <Tooltip placement="top" title="Nick sống">
                                                                            <span className="badge badge-success">Live</span>
                                                                        </Tooltip> :
                                                                        record.status == 2 || record.status == 3 || record.status == 4 ?
                                                                            <Tooltip placement="top" title={
                                                                                record.status == 2 ? "Lỗi đăng nhập" : record.status == 3 ?
                                                                                    "Checkpoint" : record.status == 4 ? "Nick die" : "Không hoạt động"
                                                                            }><span className="badge badge-danger">Die</span></Tooltip> :
                                                                            record.status == 5 ?
                                                                                <span className="badge badge-warning">Run</span> :
                                                                                <span className="badge badge-secondary">Null</span>
                                                                }
                                                                <span className="d-flex align-items-center ml-10" style={{ color: "#333b3f" }}><i className="fal fa-users mr-1"></i> {record.friends_count}</span>
                                                                {
                                                                    <Tooltip title="Số hành động đang chờ">
                                                                        <a className={cx("ml-10", record.task_count && "text-danger")}
                                                                            onClick={() => {
                                                                                if(record.task_count) this.setState({ visibleLogAction: { visible: true, data: record }})
                                                                            }}
                                                                        >
                                                                            <i className="mr-1 fal fa-calendar-alt"></i>{record.task_count}
                                                                        </a>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                },
                                                // {
                                                //     title: "Tên",
                                                //     dataIndex: "full_name",
                                                //     width: 180,
                                                // },
                                                {
                                                    title: "Thư mục",
                                                    dataIndex: "folder_name",
                                                    width: 200,
                                                    render: (value) => {
                                                        return <React.Fragment>
                                                            <span title={value} style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "block" }}>{value}</span>
                                                        </React.Fragment>
                                                    }
                                                },
                                                {
                                                    title: "Device",
                                                    width: 160,
                                                    dataIndex: "device_id"
                                                },
                                                {
                                                    title: "Proxy",
                                                    width: 160,
                                                    dataIndex: "proxy"
                                                },
                                                {
                                                    title: "Log",
                                                    dataIndex: "log",
                                                    width: 200,
                                                    render: (value, record) => {
                                                        return <React.Fragment>
                                                            {record.log} {record.logs.length > 0 ? `(${record.logs.length})` : ""}
                                                        </React.Fragment>
                                                    }
                                                },
                                                {
                                                    title: "Thời gian",
                                                    width: 160,
                                                    dataIndex: "time_created",
                                                    render: (value) => value && moment(value).format("HH:mm DD/MM/YYYY")
                                                },
                                                {
                                                    title: "Thao tác",
                                                    align: "center",
                                                    fixed: "right",
                                                    width: 120,
                                                    className: "pl-2 pr-2 table-column-action",
                                                    render: (value, record) => {
                                                        return <React.Fragment>
                                                            <div className="d-md-block d-flex flex-column">
                                                                <Tooltip title="Xem chi tiết">
                                                                    <Button size="small" type="primary"
                                                                        onClick={() => this.setState({ detailUser: record })}
                                                                    >
                                                                        <i class="far fa-eye"></i>
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="Xóa">
                                                                    <Button className="ml-md-2" size="small" type="primary" danger
                                                                        onClick={() => this.removeMany({ type: 1, id: record._id })}
                                                                    >
                                                                        <i class="far fa-trash"></i>
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(HomeView);