import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Button, Form, Input, Modal, Select, TreeSelect, InputNumber, Checkbox, Row,
    Col, Upload, Tooltip, Switch, Badge, Radio, message, notification, Steps, DatePicker, Tag, Divider, Space
} from "antd";

// import WidgetTiki1 from "./FormItem/Tiki1";
// import WidgetTiki2 from "./FormItem/Tiki2";

// import WidgetFacebook13 from "./FormItem/Facebook13";
// import WidgetFacebook14 from "./FormItem/Facebook14";

import { fetchData } from "../../../../lib/helpers";
import { API_URL } from "../../../../lib/api";
import moment from "moment";
import WidgetFormRender from "./FormItem/FormRender";
import { connect } from "react-redux";
import { CopyOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WidgetFormTimer from "./FormTimer";
import cx from "classnames";

const FormCreate = (props) => {
    const { visible, hide, platform, viewDetail, Access, isHomeCreate } = props;
    const [action, setAction] = useState();
    const [folderNick, setfolderNick] = useState([]);
    const [folderContent, setfolderContent] = useState([]);
    const [error, setError] = useState("");
    const [loading, setloading] = useState(false);
    const [listTaskAction, setlistTaskAction] = useState({
        loading: false,
        data: []
    })
    const [setupTimer, setsetupTimer] = useState({
        value: 1,
        data: []
    });

    const formRef = useRef();
    const timeSearchTaskAction = useRef();

    const listActionName = useMemo(() => {
        let data = Access.Platform.actions.filter((item) => item.platform == platform);
        return data;
    }, [platform, Access.Platform])

    const convertTime = (t) => {
        if (t < 10) {
            return `0${t}`;
        } else {
            return t;
        }
    };

    const convertContent = (content) => {
        const regex = /\n/g;
        return content.replace(regex, ",");
    };

    const b64EncodeUnicode = (str) => {
        return btoa(
            encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode("0x" + p1);
            })
        );
    }

    const getMultipleRandomByTime = (arr, elNum, time) => {
        let final = [];

        for (let index = 0; index < time * 1; index++) {
            const shuffled = [...arr].sort(() => 0.5 - Math.random());
            final.push(shuffled.slice(0, elNum * 1));
        }

        return final;
    }

    const onFinish = async (values) => {
        try {
            console.log(values);
            // return;
            setloading(true);
            let optionsObj = { ...values };

            if (optionsObj["CardDate"]) {
                const dates = optionsObj["CardDate"].split("-");
                const dateValid = `${dates[1]}/${dates[2]}`;
                optionsObj = { ...optionsObj, dateValid };
            }

            //handle commentContent
            if (optionsObj.commentContent) {
                optionsObj.commentContent = convertContent(optionsObj.commentContent);
            }

            if (
                !optionsObj.commentContent &&
                (optionsObj.actionName.includes("facebook") ||
                    optionsObj.actionName.includes("instagram"))
            ) {
                optionsObj.commentContent = "haha";
            }
            //end of handle commentContent

            //handle shareContent
            if (optionsObj.shareContent) {
                optionsObj.shareContent = convertContent(optionsObj.shareContent);
            }

            // if (optionsObj.contentComment) {
            //     optionsObj.contentComment = convertContent(optionsObj.contentComment);
            // }

            if (
                !optionsObj.shareContent &&
                (optionsObj.actionName.includes("facebook") ||
                    optionsObj.actionName.includes("instagram"))
            ) {
                optionsObj.shareContent = "haha";
            }
            //end of handle shareContent

            //handle farm options
            if (optionsObj.farmOption) {
                let farmOptionDetail = {};
                optionsObj.farmOption.forEach((option) => {
                    farmOptionDetail[option] = true;
                });
                // console.log(farmOption); //for debug
                optionsObj.farmOptionDetail = farmOptionDetail;
            }
            //end of handle farm options

            //handle loop options
            if (optionsObj.loopOption) {
                let loopOptionDetail = {};
                optionsObj.loopOption.forEach((option) => {
                    loopOptionDetail[option] = true;
                });
                // console.log(loopOption); //for debug
                optionsObj.loopOptionDetail = loopOptionDetail;
            }
            //end of handle loop options

            //handle farm options for seeding by uid list
            if (
                !optionsObj.farmOption &&
                optionsObj.actionName === "seeding_uid_facebook"
            ) {
                let farmOptionDetail = {};
                farmOptionDetail.likeWhileView = true;
                optionsObj.farmOptionDetail = farmOptionDetail;
            }
            //end of handle farm options for seeding by uid list

            //handle answer content join group
            if (optionsObj.answerContent) {
                optionsObj.answerContent = convertContent(optionsObj.answerContent);
            }

            if (
                !optionsObj.answerContent &&
                optionsObj.actionName === "join_group_facebook"
            ) {
                optionsObj.answerContent = "okey";
            }
            //end of handle answer content join group

            //handle link group join group (text area) (includes for kakao in lua)
            if (typeof optionsObj.linkGroup == "string") {
                let linkGroupInit = optionsObj.linkGroup.trim().split("\n");
                let linkGroup = [];

                linkGroupInit.forEach((lg) => {
                    let url = new URL(lg);
                    linkGroup.push(url.origin + url.pathname);
                });
                optionsObj.linkGroup = linkGroup;

            }
            //end of handle link group join group (text area) (includes for kakao in lua)

            //handle id (link) post in group
            if (
                optionsObj.linkPost &&
                optionsObj.actionName === "interact_group_post_facebook"
            ) {
                const arr = optionsObj.linkPost.split("\n");

                let newArr = [];

                arr.forEach((link, index) => {
                    if (link.split("/").slice(-1)[0]) {
                        newArr.push(link.split("/").slice(-1)[0]);
                    } else {
                        newArr.push(link.split("/").slice(-2)[0]);
                    }
                });

                optionsObj.linkPostArr = newArr;

            }
            //end of handle id (link) post in group

            //handle link post array
            if (
                optionsObj.linkPost &&
                optionsObj.actionName === "interact_regular_post_facebook"
            ) {
                optionsObj.linkPostArr = optionsObj.linkPost.split("\n");
            }
            //end of handle link post array

            //handle image URL
            if (optionsObj.postImage) {
                let postImageArray = [];
                optionsObj.postImage.fileList.forEach((fl) => {
                    postImageArray.push(fl.response.attachment.url);
                });
                optionsObj.imgURL = postImageArray;
            }
            //end of handle image URL

            //handle uidList
            if (optionsObj.uidList) {
                optionsObj.uidList = optionsObj.uidList.split("\n");
            }
            //end of handle uidList

            //handle list messages for kakao (in lua)
            if (
                optionsObj.actionName === "join_spam_group_kakaotalk" &&
                optionsObj.messages
            ) {
                optionsObj.messages = optionsObj.messages.map((dt, i) => {
                    return dt.replaceAll('"', "").trim();
                });

                optionsObj.messagesBase64 = optionsObj.messages.map((dt, i) => {
                    return dt
                        .replaceAll('"', "")
                        .trim()
                        .split("\n")
                        .map((str) => b64EncodeUnicode(str))
                        .filter((str) => str !== ""); //spit by "\n"
                });
            }
            //end of handle list messages for kakao (in lua)

            //handle list messages for kakao (android)
            if (
                optionsObj.actionName === "join_spam_group_kakaotalk" &&
                optionsObj.messages &&
                optionsObj.messagesBase64 &&
                optionsObj.linkGroup &&
                0 < parseInt(optionsObj.mesNum) &&
                parseInt(optionsObj.mesNum) < optionsObj.messages.length
            ) {
                // console.log(parseInt(optionsObj.mesNum), "abc"); //for debug
                // console.log(optionsObj.messages.length, "abc"); //for debug

                optionsObj.messagesChunk = getMultipleRandomByTime(
                    optionsObj.messagesBase64,
                    optionsObj.mesNum,
                    optionsObj.linkGroup.length
                );
            }
            //end of handle list messages for kakao (android)

            //handle delay time for android (min to sec)
            optionsObj.delaySec = 0;
            if (
                optionsObj.actionName === "join_spam_group_kakaotalk" &&
                optionsObj.loopTime > 1 &&
                optionsObj.delay > 0
            ) {
                optionsObj.delaySec = optionsObj.delay * 60 * 1000;
            }
            //end of handle delay time for android (min to sec)

            //handle begin time
            optionsObj.beginTime = moment().format("YYYY-MM-DD HH:mm:ss");
            //end of handle begin time

            if (optionsObj.timeBeforeInteractive) optionsObj.timeMillisecondBeforeInteractive = optionsObj.timeBeforeInteractive * 1000;
            if (optionsObj.timeAfterInteractive) optionsObj.timeMillisecondAfterInteractive = optionsObj.timeAfterInteractive * 1000;

            if (optionsObj.linkPages) optionsObj.linkPagesArr = optionsObj.linkPages.split("\n");

            let options = { ...optionsObj };

            delete options['name'];

            // console.log("Success submit:", options);

            let body = {
                // folderlist_uids: optionsObj.nickFolder ? optionsObj.nickFolder : "",
                action_name: optionsObj.actionName,
                options: options,
                action_type: platform,
                actions: [],
                // num_target: optionsObj.num_target,
                name: optionsObj.name,
                timer: optionsObj.timer,
                list_uids: props.list_uids || []
            };

            // if (optionsObj.list_uids) {
            //     body['list_uids'] = optionsObj.list_uids;
            //     delete body['options'].list_uids;
            // }

            let response = await fetchData({
                method: "POST",
                url: API_URL.aciton.create,
                body: JSON.stringify(body),
                headers: { "Content-Type": "application/json" },
            });

            formRef.current.resetFields();

            setAction(null);

            message.success("Thêm thành công!");

            props.onFinish(response);

        } catch (error) {
            console.log("error while Submiting:", error);

            message.error(error.message || "Gặp lỗi trong quá trình tạo action!");

            if (error.message === "Not enough uid #1") {
                setError(
                    "Không đủ uid để chia đều cho các nick, vui lòng tăng số uid hoặc giảm số nick kết bạn!"
                );
            } else if (
                error === "TypeError: Failed to construct 'URL': Invalid URL"
            ) {
                setError("Link group không hợp lệ, vui lòng nhập lại!");
            } else {
                setError(error.message || "Đã có lỗi xảy ra, vui lòng kiểm tra lại!");
            }
        } finally {
            setloading(false);
        }
    };

    const onFinishFailed = (errors) => {
        message.error("Vui lòng hoàn thành biểu mẫu trước khi tạo!");
    }

    const convertTreedata = (list, { title, key, parent_id }) => {
        let map = {},
            node,
            i;
        let roots = [];

        for (i = 0; i < list.length; i++) {
            map[list[i][key]] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node["title"] = node[title];
            node["key"] = node[key];
            node["value"] = node[key];
            if (node[parent_id] !== 0) {
                try {
                    list[map[node[parent_id]]].children.push(node);
                } catch (err) {
                    console.log(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    };

    const getFolderNick = async () => {
        try {
            const res = await fetchData({ url: API_URL.account.folder });

            setfolderNick(convertTreedata(res["data"], {
                title: "folder_name",
                key: "folder_id",
                parent_id: "parent_id"
            }));

        } catch (error) {
            message.error("Gặp lỗi trong quá trình tải folder nick!");
        }
    };

    const getFolderContent = async () => {
        try {
            const res = await fetchData({ url: API_URL.postContent.folder });
            let data = [{
                name: "Tất cả",
                parent_id: 0,
                id: 0,
                _id: "all",
            }].concat(res.data);

            setfolderContent(convertTreedata(data, {
                title: "name",
                key: "id",
                parent_id: "parent_id"
            }));

        } catch (error) {
            message.error("Gặp lỗi trong quá trình tải folder content!");
        }
    };

    useEffect(() => {
        getFolderNick();
        getFolderContent();
    }, [])

    useEffect(() => {
        if (!visible) {
            setError("");
            setsetupTimer({
                value: 1,
                data: []
            });
            if (formRef.current) {
                formRef.current.resetFields();
                setAction("");
            }
        } else {
            if (isHomeCreate) getListTaskAction();
            if (props.item) {
                copyAction(props.item);
            }
        }
    }, [visible, props.item])

    const itemFormByAction = useMemo(() => {
        let action_select = listActionName.find((item) => item.key == action);

        switch (action) {
            // case "pay_electricity_bill_tiki":
            //     return <WidgetTiki1 />
            // case "tiki_coin":
            //     return <WidgetTiki2 />
            // case "like_post_facebook":
            //     return <WidgetFacebook13 folderNick={folderNick} />
            // case "comment_post_facebook":
            //     return <WidgetFacebook14 folderNick={folderNick} />
            default:
                return action_select && <WidgetFormRender
                    action={action_select}
                    folderNick={folderNick}
                    folderContent={folderContent}
                />
        }
    }, [action])


    const copyAction = (values) => {
        try {
            let item = {
                ...values,
                name: "Copy - " + values.name
            };

            // setAction("")
            formRef.current.resetFields();

            setAction(item.options.actionName);

            formRef.current.setFieldsValue({
                ...item.options,
                name: item.name,
                copy_id: item._id
            });
        } catch (err) {

        }
    }

    const getListTaskAction = async (keyword) => {
        try {
            setlistTaskAction({ ...listTaskAction, loading: true });
            let params = {
                limit: 10,
                page: 1,
                action_type: platform
            }

            if (keyword) params['keyword'] = keyword;

            let response = await fetchData({
                url: API_URL.taskAction.index,
                params: params
            });

            listTaskAction.data = response.data;

        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setlistTaskAction({ ...listTaskAction, loading: false });
        }
    };

    return <Modal
        title={viewDetail ? "Chi tiết hành động" : `Tạo hành động ${platform}`}
        visible={visible}
        onCancel={hide}
        width={1200}
        footer={
            (action === "farm_nick_facebook" && (
                <p style={{ textAlign: "left" }}>
                    Mỗi quá trình nuôi sẽ kéo dài từ 2-4 phút. Số like, số
                    lượt kết bạn sẽ từ 3-5, số comment sẽ từ 1-2 lần
                </p>
            )) ||
            (action === "join_spam_group_kakaotalk" && (
                <p style={{ textAlign: "left" }}>
                    Không thêm tin nhắn nếu muốn các nick chỉ tham gia nhóm
                    chat (nếu chưa tham gia).Nếu có thêm tin nhắn, máy sẽ tham
                    gia (nếu chưa tham gia) và spam các tin nhắn đã thêm như
                    bình thường.
                </p>
            )) ||
            (error && <p style={{ color: "red" }}>{error}</p>)
        }
    >
        <Form
            ref={formRef}
            name="create_action"
            // labelAlign="left"
            // labelWrap={true}
            // labelCol={{
            //     span: 6,
            // }}
            // wrapperCol={{
            //     span: 18,
            // }}
            layout="vertical"
            style={{ paddingRight: "5px" }}
            initialValues={{
                loopTime: 1,
                name: `Hành động ngày ${moment().format("HH:mm DD-MM-YYYY")}`,
                pageType: "fanpage"
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            {/* <Form.Item noStyle name="list_uids" >
                <Input readOnly type="hidden" />
            </Form.Item> */}

            <div className="row">
                <div className="col-12 col-md-5">
                    {
                        isHomeCreate && <div className="text-right">
                            <Form.Item name="copy_id" label="Copy chiến dịch">
                                <Select loading={listTaskAction.loading}
                                    onChange={(e) => {
                                        if (e) {
                                            copyAction(listTaskAction.data.find((item) => item._id === e));
                                        } else {
                                            formRef.current.resetFields();
                                            setAction("");
                                        }
                                    }}
                                    placeholder="Chọn chiến dịch cần copy"
                                    allowClear
                                    showSearch
                                    onSearch={(e) => {
                                        if (timeSearchTaskAction.current) clearTimeout(timeSearchTaskAction.current);
                                        timeSearchTaskAction.current = setTimeout(() => {
                                            getListTaskAction(e);
                                        }, 300)
                                    }}
                                    suffixIcon={<i className="fa fa-copy"></i>}
                                >
                                    {
                                        listTaskAction.data.map((item) => {
                                            return <Select.Option value={item._id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    }
                    <Form.Item
                        label="Tài nguyên đã chọn"
                        required
                    >
                        <Tag color={"default"} style={{ fontSize: "14px" }}>
                            <span>{props.list_uids && props.list_uids.length} nick</span>
                            {props.device && props.device.device_groups && <>
                                <span className="ml-2 mr-2">-</span><span>{props.device.device_groups.length} box</span>
                            </>}
                            {props.device && <>
                                <span className="ml-2 mr-2">-</span><span>{props.device.device_ids.length} thiết bị</span>
                            </>}
                        </Tag>
                    </Form.Item>

                    <Form.Item name="name" label="Tên hành động"
                        rules={[
                            {
                                required: true,
                                message: "Hãy đặt tên hành động",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Loại hành động"
                        name="actionName"
                        rules={[
                            {
                                required: true,
                                message: "Hãy chọn một hành động!",
                            },
                        ]}
                    >
                        <Select
                            placeholder="chọn một loại hành động"
                            onChange={setAction}
                            disabled={viewDetail}
                        >
                            {listActionName && listActionName.map((obj2) => {
                                return (
                                    <Select.Option key={obj2.key} value={obj2.key}>
                                        {obj2.value}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                        {/* {
                            listActionName && <Checkbox.Group
                            onChange={(e) => setAction(e.target.value)}
                            disabled={viewDetail}
                            >
                                <Space direction="vertical">
                                    {
                                        listActionName.map((obj2) => {
                                            return (
                                                <Checkbox key={obj2.key} value={obj2.key}>
                                                    {obj2.value}
                                                </Checkbox>
                                            )
                                        })
                                    }
                                </Space>
                            </Checkbox.Group>
                        } */}
                    </Form.Item>
                    {
                        viewDetail ? null : <>
                            <Form.Item label={<><i className="far fa-clock mr-2"></i> Lựa chọn lên lịch</>}>
                                <Select disabled={!action} onChange={(e) => {
                                    setsetupTimer({ value: e, data: [null] });
                                }} value={setupTimer}>
                                    <Select.Option value={1}>Chạy ngay</Select.Option>
                                    <Select.Option value={2}>Lên lịch</Select.Option>
                                </Select>
                                {
                                    setupTimer.value == 2 && <div className="mt-3">
                                        <Form.List
                                            name={"timer"}
                                            initialValue={setupTimer.data}
                                        >
                                            {(fields) => (
                                                <div>
                                                    {fields.map((field) => (
                                                        <Form.Item {...field} noStyle>
                                                            <Input type="hidden" />
                                                        </Form.Item>
                                                    ))}
                                                </div>
                                            )}
                                        </Form.List>
                                        {
                                            setupTimer.data.map((item, i) => {
                                                return <>
                                                    <WidgetFormTimer
                                                        onChange={(value) => {
                                                            setupTimer.data.splice(i, 1, value);
                                                            setsetupTimer({ ...setupTimer, data: setupTimer.data })
                                                            formRef.current.setFieldsValue({
                                                                timer: setupTimer.data
                                                            })
                                                        }}
                                                        remove={i == 0 ? undefined : () => {
                                                            setupTimer.data.splice(i, 1);
                                                            setsetupTimer({ ...setupTimer, data: setupTimer.data })
                                                        }}
                                                    />
                                                </>
                                            })
                                        }
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    setsetupTimer({ ...setupTimer, data: [...setupTimer.data, null] })
                                                }}
                                            ><i className="far fa-clock mr-2"></i>Thêm lịch</Button>
                                        </div>
                                    </div>
                                }

                            </Form.Item>
                        </>
                    }
                </div>
                <div className="col-12 col-md-7 border-l">
                    {/* <h5 className="text-danger">Thiết lập nâng cao:</h5> */}
                    {itemFormByAction ? itemFormByAction : <>
                        <p className="text-center">Không có thiết lập khác</p>
                    </>}
                </div>
            </div>


            <div
                className="text-right"
            >
                {
                    viewDetail ?
                        <Form.Item
                            wrapperCol={{
                                // offset: 21,
                                // span: 16,
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={hide}
                                style={{
                                    width: 120
                                }}
                            >
                                Đóng
                            </Button>
                        </Form.Item>
                        : <>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                className="ml-2"
                                style={{
                                    width: 120
                                }}
                            >
                                Tạo mới
                            </Button>
                        </>
                }
            </div>

            {/* Hẹn giờ */}
            {/* <div className={cx(!setupTimer.visible && "d-none")}>
                <WidgetFormTimer 
                    onCancel={() => setsetupTimer({...setupTimer, visible: false })}
                />
            </div> */}
        </Form>
    </Modal>
}

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(FormCreate);